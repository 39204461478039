import * as React from 'react';
import { useEffect } from 'react';
import { TrixEditor } from 'react-trix';
import 'trix/dist/trix.css';
export const RichTextEditor = (props) => {
    useEffect(() => {
        document.addEventListener('trix-file-accept', event => {
            event.preventDefault();
        });
    }, []);
    const handleEditorReady = (editor) => {
        if (props.initialValue) {
            editor.loadHTML(props.initialValue);
        }
        // Remove the "Attach Files" button
        const attachFileButton = document.querySelector('.trix-button-group--file-tools');
        if (attachFileButton) {
            attachFileButton.remove();
        }
    };
    const handleChange = (html, text) => {
        if (props?.onChange) {
            const new_html = html?.replace('<a', '<div')?.replace('</a', '</div')?.replace('href', 'rel');
            props.onChange(new_html, text);
        }
    };
    return (React.createElement(TrixEditor, { onChange: handleChange, onEditorReady: handleEditorReady, ...props, value: props.value && props.value, mergeTags: props.mergeTags ?? [] }));
};
