import { action, observable, makeObservable } from 'mobx';
import { get, getFile, post, printSalesOrder, remove, update_domestic_order_status, patch } from '../utils/api';
import fileDownload from 'js-file-download';
import { openNotificationWithIcon } from './UIStore';
import { buildVendorBoxDataStructure } from '../utils/vendorBox';
class IntakeStore {
    constructor() {
        this.loading = false;
        this.isLoadingIntakes = false;
        this.isLoadingOuttakes = false;
        this.location = 'PB';
        this.defaultValue = 0;
        this.loadingShippment = false;
        this.salesOrderDocuments = null;
        this.salesOrder = null;
        this.salesOrderLineItems = [];
        this.lineItemQuantities = {};
        this.refactorLineItemsQuantities = [];
        this.receiveOrShip = 'receive';
        this.contact = null;
        this.previousIntakes = [];
        this.previousOuttakes = [];
        this.activePreview = null;
        this.activePreviewLineItems = null;
        this.createBoxVisible = false;
        this.editBoxVisible = false;
        this.boxInfo = {};
        this.newQuadrant = null;
        this.domesticFinish = false;
        this.hardwareNeeded = false;
        this.pdSalesOrder = null;
        this.printIntakeOutake = false;
        this.vendorSalesOrders = [];
        this.outtake = null;
        this.rmaList = null;
        this.purchase_orders = [];
        this.vendorType = 'sales-order';
        this.setVendorType = (vendorType) => {
            this.vendorType = vendorType;
        };
        this.setLocalNewQuantityIntakeLineItem = (intakeId, lineItemId, quantity) => {
            const newPreviousIntakes = this.activePreviewLineItems ? { ...this.activePreviewLineItems } : null;
            const lineItemIndex = newPreviousIntakes?.lineitem_intakes?.findIndex(obj => obj.id === lineItemId);
            if (lineItemIndex !== undefined) {
                if (lineItemIndex < 0)
                    return;
                if (newPreviousIntakes?.lineitem_intakes) {
                    newPreviousIntakes.lineitem_intakes[lineItemIndex].quantity = quantity;
                    this.activePreviewLineItems = newPreviousIntakes;
                }
            }
        };
        this.setLocation = loc => (this.location = loc);
        this.setDefaultValue = () => (this.defaultValue = 0);
        this.updateVendorQuantity = (orderId, itemId, value) => {
            const newVendorSalesOrders = this.vendorSalesOrders ? [...this.vendorSalesOrders] : [];
            const salesOrderIndex = newVendorSalesOrders.findIndex(obj => obj.order === orderId);
            if (salesOrderIndex < 0)
                return;
            const lineItemIndex = newVendorSalesOrders[salesOrderIndex].items.findIndex(obj => obj.id === itemId);
            if (lineItemIndex < 0)
                return;
            newVendorSalesOrders[salesOrderIndex].items[lineItemIndex].quantity = value;
            this.vendorSalesOrders = newVendorSalesOrders;
        };
        this.setReceiveOrShip = val => {
            this.activePreview = null;
            this.lineItemQuantities = {};
            this.receiveOrShip = val;
        };
        this.setCreateBoxVisible = (yesOrNo, outtakeId) => {
            this.loadBoxInfo();
            this.createBoxVisible = yesOrNo;
            this.boxInfo['outtakeId'] = outtakeId;
        };
        this.setEditBoxVisible = (yesOrNo, outtakeId) => {
            if (outtakeId) {
                this.outtake = this.previousOuttakes?.find(outtake => outtake.id === outtakeId);
            }
            else {
                this.outtake = undefined;
            }
            this.editBoxVisible = yesOrNo;
        };
        this.setBoxInfo = (key, val) => {
            this.boxInfo[key] = val;
        };
        this.getRMAList = async (params) => {
            const res = await get('/staff/inventory/', params);
            this.rmaList = await res.data.results;
            return res.data.results;
        };
        makeObservable(this, {
            // Observables
            loading: observable,
            isLoadingIntakes: observable,
            isLoadingOuttakes: observable,
            location: observable,
            defaultValue: observable,
            loadingShippment: observable,
            salesOrderDocuments: observable,
            salesOrder: observable,
            salesOrderLineItems: observable,
            lineItemQuantities: observable,
            refactorLineItemsQuantities: observable,
            receiveOrShip: observable,
            contact: observable,
            previousIntakes: observable,
            previousOuttakes: observable,
            activePreview: observable,
            activePreviewLineItems: observable,
            createBoxVisible: observable,
            editBoxVisible: observable,
            boxInfo: observable,
            newQuadrant: observable,
            domesticFinish: observable,
            hardwareNeeded: observable,
            pdSalesOrder: observable,
            printIntakeOutake: observable,
            vendorSalesOrders: observable,
            outtake: observable,
            rmaList: observable,
            purchase_orders: observable,
            vendorType: observable,
            // Actions
            setVendorType: action,
            setLocation: action,
            setDefaultValue: action,
            updateVendorQuantity: action.bound,
            setReceiveOrShip: action,
            setPrintIntakeOuttake: action,
            setHardwareNeeded: action,
            setSalesOrderDocuments: action,
            updateDomesticOrderStatus: action,
            getOuttakeLineItems: action,
            getSalesOrder: action,
            loadSalesOrder: action,
            intakeSalesOrderVendorBox: action,
            refactorLineItems: action,
            intakeSalesOrder: action,
            outtakeSalesOrder: action,
            deleteIntakeSalesOrder: action,
            deleteOuttakeSalesOrder: action,
            setCreateBoxVisible: action,
            setEditBoxVisible: action,
            updateLineItemQuantity: action,
            updateLineItemRemote: action,
            saveStorageForAllItems: action,
            saveStorageForIDItem: action,
            IntakeOrOuttakeAllItems: action,
            OuttakeAllIntakeItems: action,
            setBoxInfo: action,
            createBox: action,
            printLabel: action,
            cancelShipment: action,
            editBox: action,
            setQuadrant: action.bound,
            printSalesOrder: action,
            setActivePreview: action,
            setActiveViewLineItems: action,
            downloadPdf: action,
            downloadKPI: action,
            downloadBoxes: action,
            loadBoxInfo: action,
            getRMAList: action,
            clearData: action.bound,
            getDocumentsPacketStatus: action,
            getPurchaseOrderForSalesOrder: action,
            setLocalNewQuantityIntakeLineItem: action,
            setSalesOrder: action,
            setPreviousIntakes: action,
            setPreviousOuttakes: action,
        });
    }
    setPrintIntakeOuttake(value) {
        this.printIntakeOutake = value;
    }
    setHardwareNeeded(value) {
        this.hardwareNeeded = value;
    }
    setSalesOrderDocuments(value) {
        this.salesOrderDocuments = value;
    }
    async updateDomesticOrderStatus(id) {
        try {
            openNotificationWithIcon('info', 'Intaking Meow', '');
            await update_domestic_order_status(`/staff/sales-orders/${id}/update-domestic-order-status/`);
            openNotificationWithIcon('success', 'Successfully', 'Intook Some Parts');
        }
        catch (_error) {
            console.log(_error);
        }
    }
    async getOuttakeLineItems(outtakeId) {
        this.vendorSalesOrders = null;
        try {
            const { data: { results }, } = await get(`/staff/lineitem-outtakes/?outtake_id=${outtakeId}`);
            this.vendorSalesOrders = buildVendorBoxDataStructure(results);
        }
        catch (_error) {
            console.log(_error);
        }
    }
    setSalesOrder(salesOrder) {
        this.salesOrder = salesOrder;
    }
    setPreviousIntakes(intakes) {
        this.previousIntakes = intakes;
    }
    setPreviousOuttakes(outtakes) {
        this.previousOuttakes = outtakes;
    }
    async getSalesOrder(soID, receiverOrShip) {
        this.activePreview = null;
        this.activePreviewLineItems = null;
        this.isLoadingIntakes = true;
        this.isLoadingOuttakes = true;
        await get(`/staff/sales-orders/${soID}/`).then(response => {
            const { data, data: { line_items, po_files, domestic_process_needed, hardware_required }, } = response;
            this.salesOrder = data;
            this.lineItemQuantities = {};
            this.salesOrderLineItems = line_items;
            this.pdSalesOrder = data;
            this.salesOrderDocuments = po_files;
            this.domesticFinish = domestic_process_needed;
            this.hardwareNeeded = hardware_required;
        });
        if (this.salesOrder.contact != null) {
            await get(`/staff/contacts/${this.salesOrder.contact.id}/`).then(response => {
                this.contact = response.data;
            });
        }
        await get(`/staff/intakes/?salesorder_id=${this.salesOrder.id}`).then(response => {
            this.previousIntakes = response.data.results;
            this.isLoadingIntakes = false;
        });
        await get(`/staff/outtakes/?salesorder_id=${this.salesOrder.id}`).then(response => {
            this.previousOuttakes = response.data.results;
            this.isLoadingOuttakes = false;
        });
        this.loadBoxInfo();
    }
    async getPurchaseOrderForSalesOrder(soID) {
        await get(`/staff/sales-orders/purchase_orders/?salesorder_id=${soID}`).then(response => {
            this.purchase_orders = response.data;
        });
    }
    async loadSalesOrder(soID) {
        await get(`/staff/salesorder-lineitems/by_so/?id=${soID}`).then(response => {
            this.lineItemQuantities = {};
            this.salesOrderLineItems = response.data.results;
        });
    }
    async intakeSalesOrderVendorBox({ quantities, salesOrderId, customerReturn, domestic_process_needed, quadrant, vendorOrderId }, callback, print) {
        try {
            openNotificationWithIcon('info', 'Intaking Meow', '');
            await post(`/staff/sales-orders/${salesOrderId}/intake/`, {
                order: salesOrderId,
                quadrant: quadrant,
                line_items: quantities,
                location: customerReturn ? 'PB-CUSTOMER-RETURN' : 'PB',
                domestic: domestic_process_needed,
                print: print,
            });
            await this.getOuttakeLineItems(vendorOrderId);
            callback && callback();
            openNotificationWithIcon('success', 'Successfully', 'Intook Some Parts');
        }
        catch (error) {
            console.log(error);
        }
    }
    async refactorLineItems() {
        const new_line_items = [];
        Object.entries(this.lineItemQuantities).forEach(([key, value]) => {
            this.salesOrderLineItems.map(order_item => {
                if (Number(order_item.id) === Number(key)) {
                    new_line_items.push({
                        id: order_item.id,
                        name: order_item.name,
                        quantity: Number(order_item.quantity_received),
                        quantity_remaining: Number(order_item.quantity_remaining_to_receive),
                        quantity_received: Number(value),
                        extra_quantity: Number(value) - Number(order_item.quantity_remaining_to_receive),
                        storage: order_item.storage_location,
                        save_in_inventory: true,
                        reason: 'General Extra Parts',
                    });
                }
            });
        });
        this.refactorLineItemsQuantities = new_line_items;
        return new_line_items;
    }
    async intakeSalesOrder(excludeDomesticFinish, zIntake, inventory_order_items) {
        const quadrant = this.salesOrder.quadrant;
        if (this.salesOrder) {
            const itemsWithFAA = [];
            const new_line_items = [];
            Object.entries(this.lineItemQuantities).forEach(([key, value]) => {
                this.salesOrderLineItems.map(order_item => {
                    if (Number(order_item.id) === Number(key)) {
                        new_line_items.push({
                            id: order_item.id,
                            quantity: Number(value) > order_item.quantity ? order_item.quantity : Number(value),
                            storage: order_item.storage_location,
                            extra_quantities: 0,
                        });
                        if (order_item.first_article_approval)
                            itemsWithFAA.push(order_item.name);
                    }
                });
            });
            try {
                const payload = {
                    order: this.salesOrder.id,
                    quadrant: quadrant,
                    line_items: this.lineItemQuantities,
                    location: this.location,
                    domestic: excludeDomesticFinish ? false : this.domesticFinish,
                    print: this.printIntakeOutake,
                    inbound_destination: zIntake ? 'Z' : 'SZ',
                    inventory_order_items: inventory_order_items,
                };
                new_line_items.map((item) => inventory_order_items?.find((inventory_item) => {
                    if (inventory_item.id === item.id) {
                        item.extra_quantities = inventory_item.extra_quantity;
                    }
                }));
                payload.line_items = new_line_items;
                // const url_name = zIntake ? 'z-intake' : 'intake';
                await post(`/staff/sales-orders/${this.salesOrder.id}/intake/`, payload);
                await this.getSalesOrder(this.salesOrder.id, 'receive');
                openNotificationWithIcon('success', 'Successfully', 'Intook Some Parts');
                if (itemsWithFAA.length) {
                    openNotificationWithIcon('warning', 'Warning', 'This product is marked for an FAA and may require shipment ASAP. Please review In House Requirements and the Required Ship Date', 0);
                }
            }
            catch (error) {
                console.log(error);
            }
            finally {
                this.setPrintIntakeOuttake(false);
            }
        }
    }
    async outtakeSalesOrder(zOuttake, payload, poId) {
        if (this.salesOrder) {
            try {
                const url_name = zOuttake ? 'z-outtake' : 'outtake';
                const lastPayload = {
                    order: this.salesOrder.id,
                    line_items: this.lineItemQuantities,
                    print: this.printIntakeOutake,
                    ...payload,
                };
                if (poId && url_name === 'z-outtake') {
                    lastPayload['po_id'] = poId;
                }
                await post(`/staff/sales-orders/${this.salesOrder.id}/${url_name}/`, lastPayload);
                await this.getSalesOrder(this.salesOrder.id, 'receive');
                openNotificationWithIcon('success', 'Successfully', "Outtoke Some Parts Meow'");
            }
            catch (error) {
                console.log(error);
            }
            finally {
                this.setPrintIntakeOuttake(false);
            }
        }
    }
    async deleteIntakeSalesOrder(id) {
        if (this.salesOrder) {
            try {
                await remove(`/staff/intakes/${id}/`);
                await this.getSalesOrder(this.salesOrder.id, 'receive');
            }
            catch (error) {
                console.log(error);
            }
        }
    }
    async deleteOuttakeSalesOrder(id) {
        if (this.salesOrder) {
            try {
                await remove(`/staff/outtakes/${id}/`);
                await this.getSalesOrder(this.salesOrder.id, 'receive');
            }
            catch (error) {
                console.log(error);
            }
        }
    }
    updateLineItemQuantity(id, quantity) {
        this.lineItemQuantities = {
            ...this.lineItemQuantities,
            [id]: quantity,
        };
    }
    async updateLineItemRemote(payload = {}, id) {
        await patch(`/staff/lineitem-intakes/${id}/`, payload);
    }
    saveStorageForAllItems(storage) {
        this.salesOrderLineItems = this.salesOrderLineItems.map(order_item => {
            order_item.storage_location = storage;
            return order_item;
        });
    }
    saveStorageForIDItem(id, storage) {
        this.salesOrderLineItems = this.salesOrderLineItems.map(order_item => {
            if (order_item.id === id) {
                order_item.storage_location = storage;
            }
            return order_item;
        });
    }
    IntakeOrOuttakeAllItems() {
        this.lineItemQuantities = {};
        const items = this.salesOrderLineItems
            .filter(item => item.name != 'CNC Part')
            .map(item => {
            const remaining = this.receiveOrShip === 'receive' ? 'quantity_remaining_to_receive' : 'quantity_remaining_to_ship';
            this.lineItemQuantities = {
                ...this.lineItemQuantities,
                [item.id]: item[remaining],
            };
        });
    }
    OuttakeAllIntakeItems() {
        this.lineItemQuantities = {};
        const items = this.salesOrderLineItems
            .filter(item => item.name != 'CNC Part')
            .map(item => {
            const quantity_remaining_to_receive = item['quantity_remaining_to_receive'];
            const quantity_remaining_to_ship = item['quantity_remaining_to_ship'];
            const result = quantity_remaining_to_ship - quantity_remaining_to_receive;
            this.lineItemQuantities = {
                ...this.lineItemQuantities,
                [item.id]: result,
            };
        });
    }
    async createBox(boxInfo) {
        await post(`/staff/outtakes/${this.boxInfo['outtakeId']}/create_box/`, {
            ...boxInfo,
        });
        this.setCreateBoxVisible(false, this.boxInfo['outtakeId']);
        await this.getSalesOrder(this.salesOrder.id, 'ship');
        openNotificationWithIcon('success', 'Successfully', 'Created box');
    }
    async printLabel(outtake_id) {
        await post(`/staff/outtakes/${outtake_id}/print-label/`);
    }
    async cancelShipment(outtake_id, tracking_number) {
        this.loadingShippment = true;
        await post(`/staff/outtakes/${outtake_id}/cancel-shipment/`, {
            tracking_number: tracking_number,
        });
        this.getSalesOrder(this.salesOrder.id, 'ship');
        openNotificationWithIcon('success', 'Successfully', 'Shipment canceled');
        this.loadingShippment = false;
    }
    async editBox(outtake_id, payload) {
        try {
            await post(`/staff/outtakes/${outtake_id}/edit_box/`, payload);
            this.setEditBoxVisible(false);
            await this.getSalesOrder(this.salesOrder.id, 'ship');
            // openNotificationWithIcon('success', 'Successfully', 'Edited box');
        }
        catch (err) {
            console.log(err);
            // openNotificationWithIcon('danger', 'Error', `${err.status} - ${err.statusText}`);
        }
    }
    setQuadrant(quadrant) {
        this.salesOrder.quadrant = quadrant;
    }
    async printSalesOrder(instanceID, historyType, template = 'packing-slip.html') {
        try {
            openNotificationWithIcon('info', 'Printing Meow', '');
            if (template == 'packing-slip-boxes.html') {
                await printSalesOrder(`/staff/${historyType}/${instanceID}/printout_boxes/`);
            }
            else {
                await printSalesOrder(`/staff/${historyType}/${instanceID}/printout/`);
            }
            openNotificationWithIcon('success', 'Printed Order', 'Printing Meow');
        }
        catch (err) {
            console.log(err);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            openNotificationWithIcon('danger', 'Error', `${err.status} - ${err.statusText}`);
        }
    }
    async setActivePreview(url) {
        this.activePreviewLineItems = null;
        const response = await get(url);
        this.activePreview = response.data;
    }
    async setActiveViewLineItems(url) {
        this.activePreview = null;
        const response = await get(url);
        this.activePreviewLineItems = response.data;
    }
    async downloadPdf(url, name_file) {
        openNotificationWithIcon('info', 'Generating file', 'Be patient');
        getFile(url)
            .then(async (response) => {
            if (response.ok) {
                const myBlob = await response.blob();
                return {
                    blob: myBlob,
                };
            }
            else {
                const myJson = await response.json();
                return {
                    json: myJson,
                };
            }
        })
            .then(function (data) {
            if (data.blob) {
                fileDownload(data.blob, 'PrintOut.pdf');
                openNotificationWithIcon('success', 'Downloaded', 'PDF file');
            }
            else {
                openNotificationWithIcon('error', `There's no documents approved`, '');
            }
        });
    }
    async downloadKPI() {
        openNotificationWithIcon('info', 'Generating report', 'Be patient');
        const csv = await getFile('/staff/reports/fullfillment_kpi/');
        const bytes = await csv.blob();
        fileDownload(bytes, 'fullfillment-kpi.csv');
        openNotificationWithIcon('success', 'Downloaded', 'KPI CSV file');
    }
    async downloadBoxes() {
        openNotificationWithIcon('info', 'Generating report', 'Be patient');
        const csv = await getFile('/staff/reports/boxes/');
        const bytes = await csv.blob();
        fileDownload(bytes, 'boxes.csv');
        openNotificationWithIcon('success', 'Downloaded', 'Boxes CSV file');
    }
    loadBoxInfo() {
        this.boxInfo = {};
        this.boxInfo = {
            carrier: this.salesOrder.carrier != null ? this.salesOrder.carrier : 'FedEX',
            shipping_account: this.salesOrder.shipping_account_number != null ? this.salesOrder.shipping_account_number : 'PB',
            shipping_method: this.salesOrder.shipping_method != null ? this.salesOrder.shipping_method : 'Ground',
            // shipping_cost: this.salesOrder.shipping_cost != null ? this.salesOrder.shipping_cost : 0,
            shipping_prepaid: this.salesOrder.shipping_prepaid != null ? this.salesOrder.shipping_prepaid : false,
            bill_to_customer: this.salesOrder.bill_to_customer != null ? this.salesOrder.bill_to_customer : false,
            shipping_cost: 0,
            // tracking_number: '',
            // weight: '0',
            // size: '0',
            label: '',
            notes: '',
        };
    }
    clearData() {
        this.salesOrderLineItems = [];
        this.salesOrderDocuments = null;
        this.domesticFinish = false;
        this.hardwareNeeded = false;
        this.pdSalesOrder = null;
        this.salesOrder = null;
        this.previousIntakes = null;
        this.previousOuttakes = null;
        this.rmaList = null;
    }
    getDocumentsPacketStatus(sales_order_id) {
        return get(`/staff/sales-orders/${sales_order_id}/documents-packet-status/`);
    }
    async printIventory(sales_order_id) {
        openNotificationWithIcon('info', 'Printing Meow', '');
        await get(`/staff/sales-orders/${sales_order_id}/print-inventory/`);
        openNotificationWithIcon('success', 'Printed Order', 'Printing Meow');
    }
    async multipleOrdersIntake(payload, print) {
        await post(`/staff/sales-orders/multiple-intake/`, { data: payload, print: print });
    }
}
export default new IntakeStore();
