import React from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { ColumnProps } from 'antd/es/table';
import { ISalesOrderListStaff } from '@partsbadger/types';
import { Col, Row, Typography } from 'antd';
import { DateRender, Price } from '@partsbadger/library';
import { BadgerTable } from '../badger-ui';
import { SalesOrderStore } from '../../stores/SalesOrderStore';
import { useQueryParameters } from '@partsbadger/utils';
import OrderListActions from './OrderListActions';

interface ReworkOrdersListProps {
    parent_sales_order_id: number;
}

const ReworkOrdersList = (props: ReworkOrdersListProps) => {
    const history = useHistory();

    const { parent_sales_order_id } = props;

    const searchParams = useQueryParameters();

    const columns: ColumnProps<ISalesOrderListStaff>[] = [
        {
            title: 'Name',
            dataIndex: 'name',
            render: (text, record) => {
                return (
                    <div>
                        <Typography.Link
                            disabled={record?.status == 'Draft'}
                            onClick={() => history.push(`/new-sales-order-detail/${record?.id}/#basicInfo`)}
                        >
                            {record?.name}
                        </Typography.Link>
                    </div>
                );
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (text, record) => {
                return <div>{record.status}</div>;
            },
        },
        {
            title: 'Contact',
            dataIndex: 'contact',
            render: (text, record) => {
                return (
                    <Typography.Link onClick={() => history.push(`/contacts/${record?.id}/`)}>
                        {record.contact?.fullname}
                    </Typography.Link>
                );
            },
        },
        {
            title: 'Date',
            dataIndex: 'created_time',
            render: (text, record) => {
                return (
                    <div>
                        <div>{<DateRender value={record?.created_time} />}</div>
                        <div className={'italic'}>By {record.created_by.fullname}</div>
                    </div>
                );
            },
        },
        {
            title: 'Grand Total',
            dataIndex: 'grand_total',
            render: (text, record) => {
                return <Price value={record.grand_total} />;
            },
        },

        {
            title: 'Action',
            dataIndex: 'id',
            render: (text, record) => {
                return <OrderListActions record={record} />;
            },
        },
    ];

    const { entities, pagination, loading } = SalesOrderStore;

    return (
        <Row>
            <Col span={24}>
                <BadgerTable<ISalesOrderListStaff>
                    rowKey={'id'}
                    dataSource={entities}
                    loading={loading}
                    columns={columns}
                    pagination={{
                        defaultPageSize: pagination.defaultPageSize,
                        pageSize: pagination.defaultPageSize,
                        totalRecords: pagination.total,
                    }}
                ></BadgerTable>
            </Col>
        </Row>
    );
};

export default observer(ReworkOrdersList);
