import React, { useState } from 'react';
import { SalesOrderStore } from '../../../stores/SalesOrderStore';
import {
    Badge,
    Button,
    Card,
    Col,
    Input,
    message,
    Modal,
    notification,
    Row,
    Typography,
    Descriptions,
    Divider,
    Space,
    Image,
} from 'antd';
import { SalesOrderProps } from '@partsbadger/types';
import { ErrorRender, NoteCreateProps, NotesListCard, Price } from '@partsbadger/library';
import { ColumnProps } from 'antd/es/table';
import { OrderLineItemProps } from '@partsbadger/types/lib/SalesOrderInterfaces';
import { hasGroup } from '../../User';
import { INotes } from '../../../../utils/types';
import { put } from '../../../shared';
import OrderLineItemModalHistory from '../OrderLineItemModalHistory';
import { UserStore } from '../../../stores/UserStore';
import { EditOutlined, FileOutlined, FilePdfOutlined, PullRequestOutlined } from '@ant-design/icons';
import { NotesForm } from '@partsbadger/library/lib/Form/NotesForm';
import TrackingCodeColumn from './TrackingCodeColumn';
import ActionMenu from '../../TableActions/TableActions';
import ProductionStatusColumn from './ProductionStatusColumn';
import { BadgerTable } from '../../badger-ui';
import { useHistory } from 'react-router-dom';
import SalesOrderItemStatus from '../../SalesOrderLineItem/SalesOrderItemStatus';
import { useWindowSize } from '@partsbadger/utils';

interface Props {
    order: SalesOrderProps;
    keyLineItems: React.Key[];
    selectLineItems: OrderLineItemProps[];
    setSelectLineItems: (selectedRows: OrderLineItemProps[]) => void;
    setKeyLineItems: (selectedRowKeys: React.Key[]) => void;
}

const { TextArea } = Input;

const SalesOrderLineItems = (props: Props) => {
    const { order, keyLineItems, selectLineItems, setSelectLineItems, setKeyLineItems } = props;

    const history = useHistory();

    const { width } = useWindowSize();

    const [notesByItem, setNotesByItem] = useState<{ id: number; item: string; notes: INotes[] } | null>(null);
    const [showModalNotesByItem, setShowModalNotesByItem] = useState<boolean>(false);
    const [showProductFeedbackModal, setShowProductFeedbackModal] = useState<boolean>(false);
    const [itemForFeedback, setItemForFeedback] = useState<OrderLineItemProps | null>(null);
    const [currentFeedback, setCurrentFeedback] = useState<string>('');
    const [loadingButton, setLoadingButton] = useState<boolean>(false);
    const [showHistoryOrderLineItemModalById, setShowHistoryOrderLineItemModalById] = useState<number | null>(null);

    const PartDetails = (id: number, name: string, product_description: string) => {
        return (
            <span>
                <div className={'flex flex-row'}>
                    <Typography.Link
                        onClick={() => {
                            history.push(`/sales-order-line-items/${id}/`);
                        }}
                    >
                        {name !== 'CNC Part' ? name : ''}
                    </Typography.Link>
                </div>

                {name != product_description ? <div className={'text-md'}>{product_description}</div> : null}
            </span>
        );
    };

    const RenderRequiredPartnerShipDate = (record: OrderLineItemProps) => {
        if (record.revised_partner_ship_date) {
            return (
                <>
                    <div className="line-through">{record.required_partner_ship_date}</div>
                    <span className="text-red-600">{record.revised_partner_ship_date}</span>
                </>
            );
        } else {
            return record.required_partner_ship_date;
        }
    };
    const columns: ColumnProps<OrderLineItemProps>[] = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: '20%',
            render: (t, r) => {
                return PartDetails(r.id, r.name, r.product_description);
            },
            sorter: (a, b) => a.name.length - b.name.length,
        },
        {
            title: 'Required Ship Date',
            dataIndex: 'required_part_ship_date',
            key: 'required_part_ship_date',
            width: '10%',
            sorter: (a: any, b: any) =>
                Number(new Date(a.required_part_ship_date)) - Number(new Date(b.required_part_ship_date)),
        },
        {
            title: 'Required Partner Ship Date',
            dataIndex: 'required_partner_ship_date',
            key: 'required_partner_ship_date',
            width: '10%',
            render: (text, record) => {
                return RenderRequiredPartnerShipDate(record);
            },
        },
        {
            title: 'Production Status',
            dataIndex: 'status',
            key: 'status',
            width: '10%',
            render: (text, record) => {
                return (
                    <>
                        <ProductionStatusColumn order={order} order_line_item={record} />
                    </>
                );
            },
        },
        {
            title: 'Tracking Code to PB (WI)',
            dataIndex: 'vendor_tracking_code',
            key: 'vendor_tracking_code',
            align: 'center',
            render: (text, record) => {
                return <TrackingCodeColumn product_zid={record.product_zid} />;
            },
        },
        {
            title: 'Part Status',
            dataIndex: 'shipping_status',
            key: 'shipping_status',
            align: 'center',
            render: (text, record) => {
                return <SalesOrderItemStatus status={record.status} />;
            },
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
            align: 'center',
        },

        {
            title: 'Unit Price',
            dataIndex: 'list_price',
            key: 'list_price',
            align: 'center',
            render: (text, record) => <Price value={record.list_price} />,
        },
        {
            title: 'Discount Item',
            dataIndex: 'discount',
            key: 'discount',
            align: 'center',
            render: (text, record) => <Price value={record.discount} />,
        },
        {
            title: 'Total Price',
            dataIndex: 'total_after_discount',
            key: 'total_after_discount',
            align: 'right',
            render: (text, record) => <Price value={record.total_after_discount} />,
        },
        {
            title: 'Actions',
            key: 'actions',
            align: 'right',
            render: (text, record) => {
                const production_record = SalesOrderStore.quality_documents.find(
                    item => item.product_zid == record.product_zid
                );

                const actions = [
                    {
                        key: 'submit-product-feedback',
                        icon: <EditOutlined />,
                        text: 'Submit Product Feedback',
                        disabled: !(production_record && production_record.quantity_shipped > 0),
                        onClick: () => {
                            setCurrentFeedback(record.product_feedback !== undefined ? record.product_feedback : '');
                            setItemForFeedback(record);
                            setShowProductFeedbackModal(true);
                        },
                    },
                ];

                return <ActionMenu actions={actions} />;
            },
        },
    ];

    const saveProductFeedback = (lineItemId: string | number) => {
        setLoadingButton(true);
        put(`staff/sales-order-line-items/${lineItemId}/send-product-feedback/`, {
            product_feedback: currentFeedback,
        })
            .then((response: any) => {
                notification.success({ message: 'Feedback saved' });
                SalesOrderStore.getById(order.id).catch(error => {
                    message.error(`Error loading order: ${error}`);
                });
            })
            .catch((error: any) => notification.error({ message: error }))
            .finally(() => setLoadingButton(false));
    };

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: OrderLineItemProps[]) => {
            setSelectLineItems(selectedRows);
            setKeyLineItems(selectedRowKeys);
        },
    };

    const filtered_columns = React.useMemo(() => {
        return columns.filter(c => {
            if (c.key == 'invoiced_quantity') {
                return UserStore.user?.group?.includes('Finance');
            }

            return true;
        });
    }, [UserStore.user?.group]);

    const orderItems = order.line_items;

    const MobileView = () => {
        return (
            <>
                {orderItems.map(record => (
                    <Card
                        key={record.id}
                        title={
                            <div className="flex flex-row items-center justify-between">
                                {PartDetails(record.id, record.name, record.product_description)}
                                <SalesOrderItemStatus status={record.status} />
                            </div>
                        }
                        style={{ width: '100%', marginBottom: '1rem' }}
                    >
                        <Row gutter={[16, 16]} align="middle" justify="center">
                            <Col xs={24} sm={10} md={9} style={{ textAlign: 'center' }}>
                                {record.production_3d_file?.image && (
                                    <Image
                                        src={record.production_3d_file?.image ?? '#'}
                                        alt="No Image Available"
                                        width={150}
                                        height={150}
                                        style={{ objectFit: 'cover', borderRadius: '8px' }}
                                        preview={false}
                                        placeholder={
                                            <div
                                                style={{
                                                    width: 150,
                                                    height: 150,
                                                    backgroundColor: '#f0f0f0',
                                                    borderRadius: '8px',
                                                }}
                                            />
                                        }
                                    />
                                )}
                            </Col>
                            <Col xs={24} sm={14} md={15}>
                                {/* Order Details */}
                                <Descriptions column={1} size="small" layout="horizontal">
                                    <Descriptions.Item label="Required Ship Date">
                                        {record.required_part_ship_date}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Required Partner Ship Date">
                                        {RenderRequiredPartnerShipDate(record)}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Quantity">{record.quantity}</Descriptions.Item>
                                    <Descriptions.Item label="Unit Price">
                                        <Price value={record.list_price} />
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Discount">
                                        <Price value={record.discount} />
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Total Price">
                                        <Price value={record.total_after_discount} />
                                    </Descriptions.Item>
                                </Descriptions>
                            </Col>
                        </Row>

                        {/* Production Status */}
                        <Divider />
                        <Space direction="vertical" size="small" style={{ width: '100%' }}>
                            <strong>Production Status:</strong>
                            <ProductionStatusColumn order={order} order_line_item={record} />
                        </Space>

                        {/* Tracking */}
                        <Divider />
                        <Space direction="vertical" size="small" style={{ width: '100%' }}>
                            <strong>Tracking:</strong>
                            <TrackingCodeColumn product_zid={record.product_zid} />
                        </Space>
                    </Card>
                ))}
            </>
        );
    };

    const TableView = () => {
        return (
            <BadgerTable<OrderLineItemProps>
                rowKey={record => record.id.toString()}
                rowSelection={{
                    type: 'checkbox',
                    ...rowSelection,
                }}
                style={{
                    width: '100%',
                }}
                columns={filtered_columns}
                dataSource={orderItems}
                className="border"
                pagination={{
                    totalRecords: orderItems.length,
                }}
            />
        );
    };

    const isMobile = width && width <= 960;
    return (
        <>
            <Col
                span={24}
                style={{
                    marginBottom: '1rem',
                }}
            >
                {isMobile ? <MobileView /> : <TableView />}
            </Col>

            <Col
                xs={{
                    span: 24,
                    offset: 0,
                }}
                md={{
                    span: 14,
                    offset: 10,
                }}
                xl={{
                    span: 6,
                    offset: 18,
                }}
            >
                <Card className={'flex flex-col justify-between'}>
                    <div className="flex flex-row justify-between  mb-1">
                        <div className="summary-label">Subtotal</div>
                        <div className="summary-value">
                            <Price value={order?.subtotal ?? 0} />
                        </div>
                    </div>

                    <div className="flex flex-row justify-between mt-1">
                        <div className="summary-label">Discount</div>
                        <div className="summary-value">
                            <Price value={order?.discount ?? 0} />
                        </div>
                    </div>

                    <div className="flex flex-row justify-between  mt-2">
                        <div className="summary-label">Tax</div>
                        <div className="summary-value">
                            <Price value={order?.tax ?? 0} />
                        </div>
                    </div>

                    <div
                        className="flex flex-row justify-between  mt-2 pt-4"
                        style={{
                            borderTop: 'solid 1px #d9d9d9',
                        }}
                    >
                        <div className="font-bold text-xl">Grand Total</div>
                        <div className="font-bold text-xl">
                            <Price value={order?.grand_total ?? 0} />
                        </div>
                    </div>
                </Card>
            </Col>
            {showProductFeedbackModal && (
                <>
                    <Modal
                        open={showProductFeedbackModal}
                        closable={true}
                        title={'Product Feedback'}
                        onCancel={() => setShowProductFeedbackModal(false)}
                        destroyOnClose={true}
                        footer={[
                            <Button
                                loading={loadingButton}
                                key="button-request-update"
                                type="primary"
                                style={{
                                    color: '#fff',
                                    backgroundColor: '#d92923',
                                    borderColor: '#d92923',
                                    marginLeft: 'auto',
                                }}
                                onClick={async () => {
                                    if (itemForFeedback) {
                                        saveProductFeedback(itemForFeedback.id);
                                        setShowProductFeedbackModal(false);
                                    }
                                }}
                            >
                                Save Feedback
                            </Button>,
                        ]}
                    >
                        <TextArea
                            value={currentFeedback}
                            onChange={e => {
                                setCurrentFeedback(e.target.value);
                            }}
                        />
                    </Modal>

                    {showHistoryOrderLineItemModalById && (
                        <OrderLineItemModalHistory
                            id={showHistoryOrderLineItemModalById}
                            handleClose={() => setShowHistoryOrderLineItemModalById(null)}
                        />
                    )}

                    {showModalNotesByItem && notesByItem && (
                        <Modal
                            closable={true}
                            title={
                                <span className="flex items-center font-bold">
                                    <PullRequestOutlined className="mr-1" />
                                    Notes for Item: {notesByItem.item}
                                </span>
                            }
                            open={showModalNotesByItem}
                            onCancel={() => setShowModalNotesByItem(false)}
                            destroyOnClose={true}
                            width={800}
                            footer={[
                                <Button
                                    key="button-request-update"
                                    type="default"
                                    onClick={() => {
                                        setShowModalNotesByItem(false);
                                    }}
                                >
                                    Close
                                </Button>,
                            ]}
                        >
                            <NotesForm
                                handleSubmit={(data: NoteCreateProps) => {
                                    data.id = notesByItem.id;
                                    SalesOrderStore.createNoteByItem(order.id, data)
                                        .then(async () => {
                                            notification.success({ message: 'New note added' });
                                            setShowModalNotesByItem(false);
                                            await SalesOrderStore.getById(order.id);
                                        })
                                        .catch((err: any) => {
                                            notification.error({
                                                message: <ErrorRender error={err} />,
                                                placement: 'topRight',
                                            });
                                        });
                                }}
                            />
                            {notesByItem.notes.length > 0 && <NotesListCard sales_order_notes={notesByItem.notes} />}
                        </Modal>
                    )}
                </>
            )}
        </>
    );
};

export default SalesOrderLineItems;
