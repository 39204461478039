import React, { useEffect, useState } from 'react';
import { Badge, Breadcrumb, Button, Dropdown, Layout, Menu, PageHeader, Skeleton, Tabs, Card } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import { HistoryChanges } from '@partsbadger/library';
import { MoreOutlined, ReadOutlined } from '@ant-design/icons';
import { useWindowSize } from '@partsbadger/utils';

interface Props {
    title?: string | React.ReactNode;
    subtitle?: string | React.ReactNode;
    isLoading?: boolean;
    breadcrumbs?: { title: string; link?: string }[];
    topActions?: React.ReactNode[];
    handleBack?: () => void;
    footer?: React.ReactNode;
    sidebarLinks?: { title: string; link: string; icon: React.ReactNode; counter?: number; hidden?: boolean }[];
    handleSidebarClick?: (link: string) => void;
    historyChanges?: {
        model_name: string;
        pk: number;
    };
    sideBarFilter?: React.ReactNode;
}

const { Header, Content, Sider } = Layout;

const BadgerPage: React.FC<Props> = props => {
    const history = useHistory();
    const location = useLocation();

    const [selectedMenuKey, setSelectedMenuKey] = useState(location.hash.slice(1) || 'basicInfo');
    const [selectedSection, setSelectedSection] = useState<string>('overview');
    const [isSidebarCollapsed, setIsSidebarCollapsed] = useState<boolean>(window.innerWidth <= 768);
    const { width } = useWindowSize();
    const isWideScreen = width && width > 768;

    useEffect(() => {
        if (window.location.hash) {
            const hash = window.location.hash.slice(1);
            setSelectedMenuKey(hash);
            if (props.handleSidebarClick) {
                props.handleSidebarClick(hash);
            }
        }
    }, [window.location.hash]);

    useEffect(() => {
        const handleResize = () => {
            setIsSidebarCollapsed(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const breadcrumb = (
        <Breadcrumb style={{ margin: '16px 0' }}>
            {props.breadcrumbs?.map((item, index) => (
                <Breadcrumb.Item
                    key={index}
                    onClick={() => {
                        if (item.link) history.push(item.link);
                    }}
                >
                    <span
                        style={{
                            cursor: item.link ? 'pointer' : 'default',
                        }}
                    >
                        {item.title}
                    </span>
                </Breadcrumb.Item>
            ))}
        </Breadcrumb>
    );

    const displayedTopActions = isWideScreen ? props.topActions?.slice(0, 2) ?? [] : [];
    const dropdownActions = isWideScreen ? props.topActions?.slice(2) : props.topActions;

    const menu = (
        <Menu>
            {dropdownActions?.map((action, index) => (
                <Menu.Item key={index}>{action}</Menu.Item>
            ))}
        </Menu>
    );

    const renderContent =
        props.historyChanges?.model_name && props.historyChanges?.pk ? (
            <Tabs defaultActiveKey={selectedSection} onChange={key => setSelectedSection(key)} type="card">
                <Tabs.TabPane tab="Overview" key="overview">
                    {props.isLoading ? <Skeleton /> : props.children}
                </Tabs.TabPane>
                <Tabs.TabPane tab="Timeline" key="timeline">
                    <Card>
                        {props.historyChanges?.model_name && props.historyChanges?.pk && (
                            <HistoryChanges model_name={props.historyChanges.model_name} pk={props.historyChanges.pk} />
                        )}
                    </Card>
                </Tabs.TabPane>
            </Tabs>
        ) : props.isLoading ? (
            <Skeleton />
        ) : (
            props.children
        );

    return (
        <Layout style={{ minHeight: '95vh', padding: 0 }}>
            {/* Header Section */}
            <Header style={{ padding: 0 }}>
                <PageHeader
                    className="site-page-header"
                    onBack={props.handleBack}
                    title={props.isLoading ? <Skeleton.Button style={{ width: 200 }} active /> : props.title}
                    subTitle={props.isLoading ? <Skeleton.Button style={{ width: 200 }} active /> : props.subtitle}
                    extra={
                        props.isLoading
                            ? [
                                  <Skeleton.Button key={'loading1'} style={{ width: 200 }} />,
                                  <Skeleton.Button key={'loading2'} style={{ width: 200 }} />,
                              ]
                            : [
                                  ...displayedTopActions,
                                  dropdownActions && dropdownActions.length > 0 && (
                                      <Dropdown key="more" overlay={menu}>
                                          <Button>
                                              <MoreOutlined />
                                          </Button>
                                      </Dropdown>
                                  ),
                              ]
                    }
                />
            </Header>

            <Layout style={{ padding: 0, marginTop: 20 }}>
                {(props.sidebarLinks || props.sideBarFilter) && (
                    <Sider
                        width={240}
                        collapsible
                        collapsed={isSidebarCollapsed}
                        onCollapse={setIsSidebarCollapsed}
                        breakpoint="md"
                    >
                        {props.isLoading ? (
                            <div style={{ padding: 20 }}>
                                <Skeleton active paragraph={{ rows: 4 }} />
                            </div>
                        ) : (
                            <>
                                {props.sidebarLinks && (
                                    <Menu
                                        mode="inline"
                                        selectedKeys={[selectedMenuKey]}
                                        style={{ height: '100%', borderRight: 0 }}
                                        onClick={e => {
                                            if (props.handleSidebarClick) {
                                                history.replace(`#${e.key}`);
                                            }
                                        }}
                                    >
                                        {props.sidebarLinks
                                            .filter(link => !link.hidden)
                                            .map((link, index) => (
                                                <Menu.Item key={link.link} icon={link.icon ?? null}>
                                                    {link.title}
                                                    {link.counter && link.counter > 0 ? (
                                                        <Badge count={link.counter} style={{ marginLeft: 5 }} />
                                                    ) : null}
                                                </Menu.Item>
                                            ))}
                                    </Menu>
                                )}
                                {props.sideBarFilter && props.sideBarFilter}
                            </>
                        )}
                    </Sider>
                )}

                {/* Body Section */}
                <Content
                    style={{
                        padding: '0 20px',
                        height: props.footer ? '100%' : '100%',
                    }}
                >
                    {props.isLoading ? <Skeleton /> : props.breadcrumbs?.length ? breadcrumb : null}

                    {props.isLoading ? <Skeleton /> : renderContent}

                    {/* Footer Section */}
                    {props.footer && (
                        <div
                            style={{
                                borderTop: '1px solid #C7C7C7',
                                textAlign: 'center',
                                height: '10%',
                            }}
                        >
                            {props.footer}
                        </div>
                    )}
                </Content>
            </Layout>
        </Layout>
    );
};

export default BadgerPage;
