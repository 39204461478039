import React from 'react';
import '@ant-design/compatible/assets/index.css';
import { useHistory } from 'react-router-dom';
import { FilePdfOutlined } from '@ant-design/icons';
import { IContactListStaff } from '@partsbadger/types';
import { SalesOrderStore } from '@partsbadger/sales_order_tool';
import { ZOHO_BASE_URL } from '../../constants';
import ActionMenu from '../TableActions/TableActions';

interface Props {
    contact: IContactListStaff;
}
const ContactListActions = (props: Props) => {
    const { contact } = props;

    const history = useHistory();
    const [isCreatingSalesOrder, setIsCreatingSalesOrder] = React.useState(false);
    const [isCreatingQuote, setIsCreatingQuote] = React.useState(false);

    const redirectToCreateSalesOrder = async () => {
        setIsCreatingSalesOrder(true);
        await SalesOrderStore.getOrCreateSalesOrder(contact.id).then(async () => {
            history.push(`/sales-order/new/?DraftOrderID=${SalesOrderStore.order_id}`);
            await SalesOrderStore.getContactById(contact.id);
            setIsCreatingSalesOrder(false);
        });
    };

    const redirectToCreateQuote = () => {
        setIsCreatingSalesOrder(true);
        history.push(`/get-quote/?email=${contact.email}`);
        setIsCreatingQuote(false);
    };

    const actions = [
        {
            key: 'view-in-zoho',
            icon: <FilePdfOutlined />,
            text: 'View In Zoho',
            disabled: !contact.zoho_id,
            link: `${ZOHO_BASE_URL}/Contacts/${contact.zoho_id}`,
        },
        {
            key: 'create-quote',
            icon: <FilePdfOutlined />,
            text: 'Create Quote',
            loading: isCreatingQuote,
            onClick: redirectToCreateQuote,
        },
        {
            key: 'create-sales-order',
            icon: <FilePdfOutlined />,
            text: 'Create Sales Order',
            loading: isCreatingSalesOrder,
            onClick: redirectToCreateSalesOrder,
        },
    ];

    return <ActionMenu actions={actions} />;
};

export default ContactListActions;
