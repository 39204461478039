import React, { useEffect } from 'react';
import { ISalesOrderLineItemResponse } from '../../stores/SalesOrderLineItemStore';
import { BadgerTable } from '../badger-ui';
import { ColumnProps } from 'antd/es/table';
import { observer } from 'mobx-react-lite';
import { IEvent, ProductionProductStore } from '../../stores/ProductionProductStore';

interface Props {
    entity: ISalesOrderLineItemResponse;
}

const ProductionEventsByProduct = (props: Props) => {
    const { entity } = props;

    const { IProductEventsResponse } = ProductionProductStore;

    const events = IProductEventsResponse.results;
    const totalRecords = IProductEventsResponse.count;

    useEffect(() => {
        if (entity.product_zid) {
            ProductionProductStore.getEventsProductId(entity.product_zid);
        }
    }, [entity.product_zid]);

    const columns: ColumnProps<IEvent>[] = [
        {
            title: 'Date',
            dataIndex: 'created',
            key: 'created',
            render: value => new Date(value).toLocaleDateString(),
        },
        {
            title: 'Details',
            dataIndex: 'message',
            key: 'message',
        },
    ];

    return (
        <BadgerTable<IEvent>
            dataSource={events}
            columns={columns}
            pagination={{
                totalRecords: totalRecords,
                pageSize: 30,
            }}
        />
    );
};

export default observer(ProductionEventsByProduct);
