import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { IContactListStaff } from '@partsbadger/types';
import { ColumnProps } from 'antd/es/table';
import { Card, Col, Row, Typography } from 'antd';
import { DateRender } from '@partsbadger/library';
import { observer } from 'mobx-react';
import { ContactStore } from '../../stores/ContacStore';
import { useQueryParameters, ZOHO_BASE_URL } from '@partsbadger/utils';
import SendToZohoButton from '../../components/Buttons/SendToZohoButton';
import { BadgerPage, BadgerSearch, BadgerTable } from '../../components/badger-ui';
import ContactListActions from '../../components/Contact/ContactListActions';

const ContactListPage = () => {
    const history = useHistory();

    const searchParams = useQueryParameters();

    const loadData = () => {
        const params = {
            page: parseInt(searchParams.get('page') ?? '1'),
            search: searchParams.get('search') ?? '',
        };
        ContactStore.getAll(params);
    };

    useEffect(() => {
        loadData();
    }, [location.search]);

    const columns: ColumnProps<IContactListStaff>[] = [
        {
            title: 'First Name',
            dataIndex: 'name',
            render: (text, record) => {
                return (
                    <div>
                        <Typography.Link onClick={() => history.push(`/contacts/${record.id}/`)}>
                            {record.fullname}
                        </Typography.Link>
                    </div>
                );
            },
        },

        {
            title: 'Account',
            dataIndex: 'account',
            render: (text, record) => {
                return (
                    <div>
                        <Typography.Link
                            disabled={!record.account}
                            onClick={() => history.push(`/accounts/${record?.account?.id}/`)}
                        >
                            {record.account?.name}
                        </Typography.Link>
                    </div>
                );
            },
        },

        {
            title: 'Email',
            dataIndex: 'email',
            render: (text, record) => {
                return (
                    <div>
                        <div>{record.email}</div>
                    </div>
                );
            },
        },
        {
            title: 'Phone / Mobile',
            dataIndex: 'phone',
            render: (text, record) => {
                return (
                    <div>
                        <div>{record.phone}</div>
                        <div>{record.mobile}</div>
                    </div>
                );
            },
        },

        {
            title: 'Date',
            dataIndex: 'created_time',
            render: (text, record) => {
                return (
                    <div>
                        <div>{<DateRender value={record.created_time} />}</div>
                        <div className={'italic'}>By {record.owner.fullname}</div>
                    </div>
                );
            },
        },
        {
            title: 'Zoho',
            dataIndex: 'zoho_id',
            render: (text, r) => {
                return (
                    <div>
                        {r.zoho_id ? (
                            <a
                                style={{
                                    fontSize: 12,
                                    marginLeft: 4,
                                }}
                                target={'_blank'}
                                rel="noreferrer"
                                href={`${ZOHO_BASE_URL}/Contacts/${r.zoho_id}`}
                            >
                                View In Zoho
                            </a>
                        ) : (
                            <SendToZohoButton
                                id={r.id}
                                module={'contacts'}
                                onFinish={() => {
                                    loadData();
                                }}
                            />
                        )}
                    </div>
                );
            },
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            render: (text, record) => {
                return <ContactListActions contact={record} />;
            },
        },
    ];

    const data = ContactStore.contacts;
    const loading = ContactStore.isLoading;

    return (
        <BadgerPage title={'Contacts'}>
            <Card>
                <Row>
                    <Col span={24}>
                        <BadgerSearch />
                    </Col>
                </Row>

                <br />

                <Row>
                    <Col span={24}>
                        <BadgerTable<IContactListStaff>
                            rowKey={'id'}
                            dataSource={data}
                            loading={loading}
                            columns={columns}
                            pagination={{
                                pageSize: ContactStore.pagination.pageSize,
                                totalRecords: ContactStore.pagination.total,
                            }}
                        ></BadgerTable>
                    </Col>
                </Row>
            </Card>
        </BadgerPage>
    );
};

export default observer(ContactListPage);
