import React from 'react';
import { Card, Descriptions, Tag, Typography } from 'antd';

interface Props {
    sampleApprovalStatus: string;
    sampleApprovedDate: string;
    sampleCustomerStatement: string;
    sampleOriginal: {
        id: number | null;
        name: string;
    };
}

const FirstArticleApprovalInformation: React.FC<Props> = ({
    sampleApprovalStatus,
    sampleApprovedDate,
    sampleOriginal,
    sampleCustomerStatement,
}) => {
    const getColor = (status: string) => {
        switch (status) {
            case 'Approved':
                return 'green';
            case 'Conditional Approval':
                return 'gold';
            case 'Reject and Assign Same Vendor':
            case 'Reject and Back to Bidding':
                return 'red';
            default:
                return 'warning';
        }
    };

    return (
        <Card title="First Article Approval Information" bordered={false}>
            <Descriptions column={1} bordered>
                <Descriptions.Item label="Sample Approval status">
                    <Tag color={getColor(sampleApprovalStatus)}>{sampleApprovalStatus}</Tag>
                </Descriptions.Item>
                <Descriptions.Item label="Sample Original">
                    <Typography.Link
                        disabled={!sampleOriginal.id}
                        href={`/sales-order-line-items/${sampleOriginal.id}`}
                    >
                        {sampleOriginal.name}
                    </Typography.Link>
                </Descriptions.Item>
                <Descriptions.Item label="Sample Approved date">{sampleApprovedDate}</Descriptions.Item>
                <Descriptions.Item label="Sample Customer Statement">{sampleCustomerStatement}</Descriptions.Item>
            </Descriptions>
        </Card>
    );
};

export default FirstArticleApprovalInformation;
