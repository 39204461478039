import React from 'react';
import { Col, Descriptions, Divider, Row, Typography } from 'antd';
import { SalesOrderProps } from '@partsbadger/types';
import {
    CalendarOutlined,
    DownloadOutlined,
    FileOutlined,
    NumberOutlined,
    UserOutlined,
    WalletOutlined,
    EyeOutlined,
} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

interface Props {
    order: SalesOrderProps;
}
const { Text, Link } = Typography;

const SalesOrderDetailHeader = ({ order }: Props) => {
    const history = useHistory();

    return (
        <div className="order-header">
            <Row gutter={[16, 16]} align="middle">
                <Col xs={24} md={12}>
                    <Text strong>
                        <NumberOutlined /> PO Number:
                    </Text>{' '}
                    <Text>
                        {order.purchase_order || '--'}{' '}
                        {order.po_files.map(p => {
                            return (
                                <Link key={p.id} href={p.file} target={'_blank'}>
                                    <DownloadOutlined />
                                </Link>
                            );
                        })}
                    </Text>
                </Col>
                <Col xs={24} md={12}>
                    <Text strong>
                        <CalendarOutlined /> Ship Date:
                    </Text>{' '}
                    <Text>{order.required_ship_date || 'In Review'}</Text>
                </Col>
            </Row>

            <Row gutter={[16, 16]} align="middle" style={{ marginTop: 8 }}>
                <Col xs={24} md={12}>
                    <Text strong>
                        <UserOutlined /> Account:
                    </Text>{' '}
                    <Link
                        onClick={() => {
                            history.push(`/accounts/${order.account?.id}`);
                        }}
                    >
                        {order.account?.name || '-'}
                    </Link>
                </Col>
                <Col xs={24} md={12}>
                    <Text strong>
                        <UserOutlined /> Contact:
                    </Text>
                    <Link
                        onClick={() => {
                            history.push(`/contacts/${order.contact?.id}`);
                        }}
                    >
                        {order.contact?.fullname || ''} - {order.contact?.email || ''}
                    </Link>
                    <Text></Text>
                </Col>
            </Row>

            <Row gutter={[16, 16]} align="middle" style={{ marginTop: 8 }}>
                <Col xs={24} md={12}>
                    <Text strong>
                        <FileOutlined /> Type:
                    </Text>{' '}
                    <Text>
                        {order.type}{' '}
                        {order.duplicated_from && (
                            <Link
                                onClick={() => {
                                    history.push(`/new-sales-order-detail/${order.duplicated_from}/#basicInfo`);
                                }}
                            >
                                (Original Order {order.duplicated_from})
                            </Link>
                        )}
                    </Text>
                </Col>
                <Col xs={24} md={12}>
                    <Text strong>
                        <UserOutlined /> Owner:
                    </Text>{' '}
                    <Text>{order.owner?.fullname || '-'}</Text>
                </Col>
            </Row>

            <Row gutter={[16, 16]} align="middle" style={{ marginTop: 8 }}>
                <Col xs={24} md={12}>
                    <Text strong>
                        <FileOutlined /> Deal:
                    </Text>{' '}
                    <Link
                        onClick={() => {
                            history.push(`/deals/${order.deal}`);
                        }}
                    >
                        {order.deal_related?.name || '-'}
                    </Link>
                </Col>
                <Col xs={24} md={12}>
                    <Text strong>
                        <FileOutlined /> Quotes:
                    </Text>{' '}
                    <Text>
                        {order.quotes?.map(quote => (
                            <span key={quote.id}>
                                <Link
                                    onClick={() => {
                                        history.push(`/quotes/${quote.id}/details/`);
                                    }}
                                >
                                    {quote.name},{' '}
                                </Link>
                            </span>
                        ))}
                    </Text>
                </Col>
            </Row>
            <Row gutter={[16, 16]} align="middle" style={{ marginTop: 8 }}>
                <Col xs={24} md={12}>
                    <Text strong>
                        <WalletOutlined /> Payment Terms:
                    </Text>{' '}
                    <Text>{order.payment_terms || '-'}</Text>
                </Col>
                <Col xs={24} md={12}>
                    <Text strong>
                        <WalletOutlined /> Payment Info:
                    </Text>{' '}
                    <Text>
                        {order.paid_in_full ? 'Paid In Full' : '-'}
                        {order.payment_date ? `, Paid on ${order.payment_date}` : ''}
                        {order.payment_notes ? `, ${order.payment_notes}` : ''}
                    </Text>
                </Col>
            </Row>
            <Row gutter={[16, 16]} align="middle" style={{ marginTop: 8 }}>
                <Col xs={24} md={12}>
                    <Text strong></Text> <Text></Text>
                </Col>
                <Col xs={24} md={12}>
                    <Text strong>
                        <EyeOutlined /> Reviewed By:
                    </Text>{' '}
                    <Text>{order.design_engineer ? order.design_engineer.fullname : '-'}</Text>
                </Col>
            </Row>

            <Divider />
        </div>
    );
};

export default SalesOrderDetailHeader;
