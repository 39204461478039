import React from 'react';
import { Card, Descriptions, Empty, Tag, Typography } from 'antd';
import { SalesOrderStore } from '../../stores/SalesOrderStore';
import { BadgerTable } from '../badger-ui';
import { ColumnProps } from 'antd/es/table';
import { IShipment, IShipmentLineItem } from '../../stores/ShipmentStore';
import { useHistory } from 'react-router-dom';

interface Props {
    shipment: IShipment;
}

const ShipmentDetails = (props: Props) => {
    const shipData = props.shipment;
    const history = useHistory();

    const columns: ColumnProps<IShipmentLineItem>[] = [
        {
            title: 'Part Number',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (
                <Typography.Link
                    onClick={() => {
                        history.push(`/sales-order-line-item/${record.id}`);
                    }}
                >
                    {record.sales_order_lineitem.name}
                </Typography.Link>
            ),
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
        },
    ];

    return (
        <>
            <Card style={{ width: '100%' }} title={<div></div>}>
                <Descriptions bordered column={1}>
                    <Descriptions.Item label="Shipping Address">
                        {shipData.box.recipient_name}
                        <br />
                        {shipData.box.recipient_street}
                        <br />
                        {shipData.box.recipient_city}, {shipData.box.recipient_state} {shipData.box.recipient_zip_code}
                        <br />
                        {shipData.box.recipient_country}
                        <br />
                    </Descriptions.Item>

                    <Descriptions.Item label="Carrier">
                        {shipData.box.carrier}, {shipData.box.shipping_method}
                    </Descriptions.Item>

                    <Descriptions.Item label="Shipping Account">
                        {shipData.box.account_type},{shipData.box.shipping_account}
                    </Descriptions.Item>
                    <Descriptions.Item label="Shipping Tracking Number">
                        {shipData.box.tracking_number}
                    </Descriptions.Item>
                    <Descriptions.Item label="Shipping Notes">{shipData.box.notes}</Descriptions.Item>

                    <Descriptions.Item label="Destination">{shipData.outbound_destination || 'N/A'}</Descriptions.Item>
                    <Descriptions.Item label="Notification Delivered">
                        {shipData.box.notification_delivered ? <Tag color="green">Yes</Tag> : <Tag color="red">No</Tag>}
                    </Descriptions.Item>
                </Descriptions>
            </Card>

            <br />
            <Card>
                <BadgerTable<IShipmentLineItem>
                    rowKey={record => record.id.toString()}
                    columns={columns}
                    className="border"
                    dataSource={shipData.line_items}
                    pagination={{
                        totalRecords: SalesOrderStore.invoices.length,
                    }}
                    locale={{
                        emptyText: (
                            <Empty
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                description={<span style={{ color: '#000' }}>This order does not have invoices.</span>}
                            />
                        ),
                    }}
                />
            </Card>
        </>
    );
};

export default ShipmentDetails;
