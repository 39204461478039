import React from 'react';
import moment from 'moment';
import { SalesOrderStore } from '../../../stores/SalesOrderStore';
import { OrderLineItemProps } from '@partsbadger/types/lib/SalesOrderInterfaces';
import { SalesOrderProps } from '@partsbadger/types';
import { observer } from 'mobx-react-lite';

interface Props {
    order: SalesOrderProps;
    order_line_item: OrderLineItemProps;
}

/**
 *    // 1. Part is in production and not exist yet
 *     // 2. Part is submitted partially US
 *     // 3. Part is submitted full to US
 *     // 4. Part is domestic process
 *     // 5  Part is submitted to Customer Partial
 *     // 6. Part is submitted to Customer in Full
 */
const ProductionStatusColumn = (props: Props) => {
    const { order_line_item: record, order } = props;

    const production_record = SalesOrderStore.quality_documents.find(item => item.product_zid === record.product_zid);

    if (production_record) {
        const quantity_shipped = production_record.quantity_shipped;
        const required_partner_ship_date = production_record.required_partner_ship_date;
        const revised_ship_date = production_record.revised_ship_date;
        const current_date = production_record.current_date;

        if (required_partner_ship_date && quantity_shipped === 0 && !revised_ship_date) {
            const datePartner = moment(required_partner_ship_date, 'YYYY-MM-DD');
            const currentDate = moment(current_date, 'YYYY-MM-DD');
            const daysDiff = datePartner.diff(currentDate, 'days');

            return <span>{daysDiff >= 0 ? 'On Time' : 'Please Hold for updates'}</span>;
        } else if (required_partner_ship_date && quantity_shipped === 0 && revised_ship_date) {
            return <span>New Est. to Ship</span>;
        } else if (order?.status === 'Received in Full') {
            return <span>Received in Full</span>;
        } else if (order?.status === 'Received in Full - Domestic Finish') {
            return <span>Pending secondary</span>;
        } else if (order?.status.startsWith('Received - Under Review')) {
            return <span>Under review, please hold</span>;
        } else if (order?.domestic_process_needed === true) {
            return <span>Please hold for updates (shop)</span>;
        } else if (production_record.quantity_shipped > 0 && order?.intake_status === 'Intaken None') {
            return <span>On way to WI</span>;
        } else if (record.quantity - production_record.quantity_shipped <= 0) {
            return <span>Shipped in Full to USA</span>;
        } else {
            return <span>No Data</span>;
        }
    }

    return <span>--</span>;
};

export default observer(ProductionStatusColumn);
