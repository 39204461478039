import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Empty, Typography } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { BadgerTable } from '../../badger-ui';
import { ISalesOrderLineItem, SalesOrderLineItemStore } from '../../../stores/SalesOrderLineItemStore';

interface IProps {
    sales_order_line_item_id: number;
}

const Shipments: React.FC<IProps> = props => {
    const [loading, setLoading] = useState<boolean>(false);

    const history = useHistory();

    useEffect(() => {
        if (props.sales_order_line_item_id) {
            // SalesOrderLineItemStore.getInvoicesByLineItemId(props.sales_order_line_item_id);
        }
    }, [props.sales_order_line_item_id]);

    const columns: ColumnProps<ISalesOrderLineItem>[] = [
        {
            title: 'Invoice Number',
            dataIndex: 'invoice_number',
            key: 'invoice_number',
            render: (text, record) => {
                return (
                    <Typography.Link onClick={() => history.push(`/invoices/${record?.invoice_id}/`)}>
                        {text}
                    </Typography.Link>
                );
            },
        },

        {
            title: 'Invoice Date',
            dataIndex: 'invoice_date',
            key: 'invoice_date',
            // width: '10%',
            render: (text, record) => {
                return <span>{dayjs(record.invoice_date).format('MM/DD/YYYY')}</span>;
            },
        },
        {
            title: 'Quantity Invoiced',
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render(text, record) {
                return <span>{record.status}</span>;
            },
        },
    ];

    return (
        <BadgerTable<ISalesOrderLineItem>
            columns={columns}
            className="border"
            dataSource={SalesOrderLineItemStore.invoices}
            pagination={{
                totalRecords: SalesOrderLineItemStore.invoices.length,
            }}
            locale={{
                emptyText: (
                    <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={<span style={{ color: '#000' }}>This product doest not have invoices.</span>}
                    />
                ),
            }}
        />
    );
};

export default observer(Shipments);
