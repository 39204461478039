import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { IContactListStaff } from '@partsbadger/types';
import { ColumnProps } from 'antd/es/table';
import { Col, Row, Typography } from 'antd';
import { DateRender } from '@partsbadger/library';
import { observer } from 'mobx-react';
import { ContactStore } from '../../stores/ContacStore';
import { useQueryParameters } from '@partsbadger/utils';
import ActionMenu from '../../components/TableActions/TableActions';
import { FileOutlined, FilePdfOutlined } from '@ant-design/icons';
import { ZOHO_BASE_URL } from '../../constants';
import { BadgerTable, BadgerSearch } from '../badger-ui';
import ContactListActions from '../Contact/ContactListActions';

interface Props {
    account_id: number;
}

const ContactsByAccount = ({ account_id }: Props) => {
    const history = useHistory();

    const searchParams = useQueryParameters();

    const loadData = () => {
        const params = {
            page: parseInt(searchParams.get('page') ?? '1'),
            search: searchParams.get('search') ?? '',
            account: account_id,
        };
        ContactStore.getAll(params);
    };

    useEffect(() => {
        loadData();
    }, [location.search]);

    const columns: ColumnProps<IContactListStaff>[] = [
        {
            title: 'First Name',
            dataIndex: 'name',
            render: (text, record) => {
                return (
                    <div>
                        <Typography.Link onClick={() => history.push(`/contacts/${record.id}/`)}>
                            {record.fullname}
                        </Typography.Link>
                    </div>
                );
            },
        },
        {
            title: 'Email',
            dataIndex: 'email',
            render: (text, record) => {
                return (
                    <div>
                        <div>{record.email}</div>
                    </div>
                );
            },
        },
        {
            title: 'Phone / Mobile',
            dataIndex: 'phone',
            render: (text, record) => {
                return (
                    <div>
                        <div>{record.phone}</div>
                        <div>{record.mobile}</div>
                    </div>
                );
            },
        },

        {
            title: 'Date',
            dataIndex: 'created_time',
            render: (text, record) => {
                return (
                    <div>
                        <div>{<DateRender value={record.created_time} />}</div>
                        <div className={'italic'}>By {record.owner.fullname}</div>
                    </div>
                );
            },
        },
        {
            title: 'Action',
            dataIndex: 'id',
            render: (text, record) => {
                return <ContactListActions contact={record} />;
            },
        },
    ];

    const { contacts, isLoading, pagination } = ContactStore;

    return (
        <div>
            <Row>
                <Col span={24}>
                    <BadgerSearch />
                </Col>
            </Row>

            <br />

            <Row>
                <Col span={24}>
                    <BadgerTable<IContactListStaff>
                        rowKey={'id'}
                        dataSource={contacts}
                        loading={isLoading}
                        columns={columns}
                        pagination={{
                            defaultPageSize: pagination.defaultPageSize,
                            pageSize: pagination.defaultPageSize,
                            totalRecords: pagination.total,
                        }}
                    ></BadgerTable>
                </Col>
            </Row>
        </div>
    );
};

export default observer(ContactsByAccount);
