import React, { useEffect, useState } from 'react';
import { get, post } from '../../shared';
import { useHistory, useLocation } from 'react-router-dom';

import { Badge, Col, Divider, Drawer, Row, Table, Tooltip, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { DateRender, ErrorRender } from '@partsbadger/library';
import { fetchRFQS } from '../../redux/actions/quoteActions';
import Stages from '../../components/Stages';
import { RfqFilterForm } from '../../components/Rfq/form';
import NextRfqButton from '../../components/Rfq/NextRfqButton';
import { hasPermission } from '../../components/User';
import { PaginationComponent } from '../../components/Inputs/Pagination';
import { observer } from 'mobx-react';
import { Chat } from '@partsbadger/quote-tool';

import { useQuery } from '../../Hooks';
import { QuoteListProps, RFQListProps } from '@partsbadger/types';
import QuoteMenuActions from '../Quote/QuoteMenuActions';

const { Column } = Table;

interface IStateProps {
    quotes: QuoteListProps;
}

export const RfqList = observer(() => {
    const [quote_notifications, setQuoteNotifications] = useState([]);
    const [rfq_notification_id, setRfqNotificationId] = useState<number>(0);
    const [quote_notification_id, setQuoteNotificationsId] = useState<number>(0);

    const quotes = useSelector((states: IStateProps) => states.quotes);
    const searchParams = useQuery();
    const location = useLocation();
    const history = useHistory();

    const dispatch = useDispatch();

    const loadRfqs = () => {
        dispatch(fetchRFQS(searchParams));
    };

    useEffect(() => {
        loadRfqs();
    }, [location.search]);

    useEffect(() => {
        if (quote_notification_id > 0) {
            get(`staff/quotes/${quote_notification_id}/notifications/`).then((response: any) => {
                setQuoteNotifications(response.data);
            });
        }
    }, [quote_notification_id]);

    useEffect(() => {
        if (rfq_notification_id > 0) {
            get(`staff/rfq/${rfq_notification_id}/notifications/`).then((response: any) => {
                setQuoteNotifications(response.data);
            });
        }
    }, [rfq_notification_id]);

    const dataSource = quotes?.rfqs;

    return (
        <>
            <ErrorRender error={quotes.error} />

            <RfqFilterForm />

            {hasPermission('quotes.add_quote') && (
                <Divider>
                    <div className={'title'}>
                        RFQ <NextRfqButton />
                    </div>
                </Divider>
            )}

            <Row className={'row-list'}>
                <Col xs={24}>
                    <Table
                        rowKey={'id'}
                        dataSource={dataSource}
                        loading={quotes.isLoading}
                        pagination={false}
                        footer={() => {
                            return <PaginationComponent {...quotes.pagination_rfqs} />;
                        }}
                        scroll={{ x: '1200px' }}
                    >
                        <Column title="#" dataIndex="id" key="id" />
                        <Column<RFQListProps>
                            title="Quote"
                            dataIndex="quote"
                            key="quote"
                            render={(text, record) => {
                                return (
                                    <div>
                                        {record?.quote ? (
                                            <Typography.Link
                                                onClick={() => {
                                                    if (record.quote) {
                                                        history.push(`/quotes/${record.quote.id}/details/`);
                                                    }
                                                }}
                                            >
                                                {record.quote.name}
                                            </Typography.Link>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                );
                            }}
                        />

                        <Column<RFQListProps>
                            title="Contact"
                            dataIndex="contact"
                            key="contact"
                            render={(text, record) => {
                                if (record.contact) {
                                    return (
                                        <div>
                                            {record.contact.fullname} <br />
                                            {record.account ? (
                                                <Typography.Link
                                                    onClick={() => {
                                                        if (record.account) {
                                                            history.push(`accounts/${record.account.id}`);
                                                        }
                                                    }}
                                                    style={{
                                                        fontStyle: 'italic',
                                                    }}
                                                >
                                                    {record.account_name}
                                                </Typography.Link>
                                            ) : null}
                                        </div>
                                    );
                                } else {
                                    return (
                                        <div>
                                            <Badge count={'Pending'} />
                                            <p>
                                                {record.contact_first_name} {record.contact_last_name}
                                            </p>
                                        </div>
                                    );
                                }
                            }}
                        />
                        <Column<RFQListProps>
                            title="Created Time"
                            dataIndex="created_time"
                            key="created_time"
                            render={(text, record) => {
                                return (
                                    <div>
                                        <DateRender value={record.created_time} />
                                        <div className={'italic'}>By: {record.created_by.fullname}</div>
                                    </div>
                                );
                            }}
                        />

                        <Column<RFQListProps>
                            title="Source"
                            dataIndex="source"
                            key="source"
                            render={(text, record) => {
                                return record.quote?.source;
                            }}
                        />

                        <Column<RFQListProps>
                            title="Status"
                            dataIndex="quote"
                            key="quote"
                            render={(text, record) => {
                                if (record.quote && !record.unquoted) {
                                    return (
                                        <div className={'flex flex-col w-full'}>
                                            {record.quote.initiate_go_team && record.quote?.stage !== 'Completed' ? (
                                                <Tooltip
                                                    title={`Why? ${record.quote.go_team_info?.why}, Need by answer: ${
                                                        record.quote.go_team_info?.need_by_answer || '--'
                                                    }`}
                                                >
                                                    <Badge
                                                        className="pt-2"
                                                        count={'Waiting on Go Team'}
                                                        style={{ backgroundColor: '#1a7703' }}
                                                    />
                                                </Tooltip>
                                            ) : (
                                                <>
                                                    <div>
                                                        <Stages stage={record.quote?.stage} />
                                                    </div>
                                                    {record.quote?.stage === 'Vendor Quoting' && (
                                                        <div
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                            className={'w-full font-bold capitalize'}
                                                        >
                                                            {record.quote?.vendor_rfq_type}
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    );
                                } else if (record.unquoted) {
                                    return (
                                        <span>
                                            <Stages stage={'Unquoted'} />
                                            <div> {record.unquoted_notes}</div>
                                        </span>
                                    );
                                }

                                return <Stages stage={''} />;
                            }}
                        />

                        <Column<RFQListProps>
                            title="Sales Rep"
                            dataIndex="owner"
                            key="owner"
                            render={(text, record) => {
                                return record.owner.fullname;
                            }}
                        />

                        <Column<RFQListProps>
                            title="Design Engineer"
                            dataIndex="design_engineer"
                            key="design_engineer"
                            render={(text, record) => {
                                return record?.quote?.design_engineer?.fullname;
                            }}
                        />

                        <Column<RFQListProps>
                            title="Tags"
                            dataIndex="itar"
                            key="itar"
                            render={(text, record) => {
                                return (
                                    <div className="flex flex-col">
                                        {record.itar && <Badge count={'ITAR'} style={{ backgroundColor: '#4b5320' }} />}
                                        {/*{record.quote?.safe_to_autocomplete && (*/}
                                        {/*    <Badge count={'Auto Green'} style={{ backgroundColor: '#27AE60' }} />*/}
                                        {/*)}*/}
                                        {record.quote?.type === 'Requote' && (
                                            <>
                                                <Badge count={'Requote'} style={{ backgroundColor: '#8E44AD' }} />
                                                {record.quote.initiate_go_team && (
                                                    <Tooltip
                                                        title={`Why? ${
                                                            record.quote.go_team_info?.why
                                                        }, Need by answer: ${
                                                            record.quote.go_team_info?.need_by_answer || '--'
                                                        }`}
                                                    >
                                                        <Badge
                                                            className="pt-2"
                                                            count={'Go Team'}
                                                            style={{ backgroundColor: '#1a7703' }}
                                                        />
                                                    </Tooltip>
                                                )}
                                            </>
                                        )}
                                        {record.quote?.domestic_quoting && (
                                            <Badge count={'Domestic'} style={{ backgroundColor: '#DAB540' }} />
                                        )}
                                        {record.quote?.customer_supplied_material && (
                                            <Badge count={'Customer Material'} style={{ backgroundColor: '#18adaa' }} />
                                        )}
                                    </div>
                                );
                            }}
                        />

                        <Column<RFQListProps>
                            title="Notifications"
                            render={(text, record) => {
                                return (
                                    <div>
                                        <div
                                            className={'link'}
                                            onClick={() => {
                                                if (record.quote?.id) {
                                                    setQuoteNotificationsId(record.quote?.id);
                                                }
                                            }}
                                        >
                                            Messages
                                        </div>
                                    </div>
                                );
                            }}
                        />

                        <Column<RFQListProps>
                            title="Action"
                            key="action"
                            render={(text, record) => {
                                if (!record.quote) return;

                                return (
                                    <QuoteMenuActions
                                        quote={{
                                            id: record.quote.id,
                                            name: record.quote.name,
                                            completed: record.quote.completed,
                                            deal: record.quote.deal,
                                        }}
                                        rfq={{
                                            id: record.id,
                                            account: record.account
                                                ? {
                                                      id: record.account.id,
                                                      name: record.account.name,
                                                  }
                                                : null,
                                            contact: record.contact
                                                ? {
                                                      id: record.contact.id,
                                                      name: record.contact.fullname,
                                                  }
                                                : null,
                                            owner: {
                                                id: record.owner.id,
                                                name: record.owner.fullname,
                                            },
                                        }}
                                        handleRefresh={() => {
                                            loadRfqs();
                                        }}
                                    />
                                );
                            }}
                        />
                    </Table>
                </Col>
            </Row>

            {(quote_notification_id || rfq_notification_id) && (
                <Drawer
                    title="Notifications"
                    placement="right"
                    closable={true}
                    width={600}
                    onClose={() => {
                        setQuoteNotificationsId(0);
                        setRfqNotificationId(0);
                    }}
                    open={true}
                >
                    <Chat
                        height={450}
                        messages={quote_notifications}
                        onSendMessage={payload => {
                            const formData = new FormData();

                            if (payload.body) {
                                formData.append('body', payload.body);
                            }

                            if (payload.notify_owner) {
                                formData.append('notify_owner', String(payload.notify_owner));
                            }

                            payload.attachments.forEach((item: any) => {
                                formData.append('attachments_files', item);
                            });

                            const url = `staff/${quote_notification_id ? 'quotes' : 'rfq'}/${
                                quote_notification_id || rfq_notification_id
                            }/notifications/`;

                            post(url, formData, 1, true)
                                .then((response: any) => {
                                    setQuoteNotifications(response.data);
                                })
                                .catch((error: any) => console.log(error));
                        }}
                    />
                </Drawer>
            )}
        </>
    );
});
