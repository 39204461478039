import React, { useMemo } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, DatePicker, InputNumber } from 'antd';
import SalesOrderBatchStore from '../Stores/SalesOrderBatchStore';
import { observer } from 'mobx-react';
import disabledDate from '../utils/utils';
import SalesOrderStore from '../Stores/SalesOrderStore';
import dayjs from 'dayjs';
const SalesOrderAddBatchForm = observer((props) => {
    const { getFieldDecorator } = props.form;
    const { order_items } = SalesOrderStore;
    const line_item = order_items?.find(item => item.id === props?.order_item_id);
    const order_item = SalesOrderStore.order_items?.find(or => props.order_item_id === or.id);
    const leadTime = order_item?.quote_has_product?.lead_time;
    // const minDate = leadTime ? dayjs().add(leadTime, 'day') : null;
    // Nick requested to change the minDate to 17 for now
    const minDate = 17;
    const handleSubmitBatch = e => {
        e.preventDefault();
        props.form.validateFields(['batch_ship_part_date', 'batch_quantity'], (err, values) => {
            if (!err) {
                if (values.batch_quantity > 0) {
                    const batch_ship_part_date = values.batch_ship_part_date.format('MM-DD-YYYY');
                    const batch_quantity = values.batch_quantity;
                    props.onSubmitted({ batch_ship_part_date: batch_ship_part_date, batch_quantity: batch_quantity });
                    props.form.resetFields();
                    SalesOrderBatchStore.getQuantity(props.order_item_id);
                    SalesOrderBatchStore.getMissingQuantity(SalesOrderBatchStore.original_quantity, SalesOrderBatchStore.total_quantity, line_item?.quantity_sample_required ? line_item?.quantity_sample_required : 0);
                }
            }
        });
    };
    const handleDisabledDates = current => {
        // Ensure current is a dayjs object and set to start of day for accurate comparison
        const currentDate = dayjs(current).startOf('day');
        // Disable dates before minDate
        const isBeforeMinDate = currentDate.isBefore(minDate);
        // Disable specific dates
        const isDisabledDate = disabledDate(currentDate);
        return isBeforeMinDate || isDisabledDate;
    };
    const getMaxQuantity = () => {
        if (!line_item || !props?.order_item_id)
            return 0;
        const totalBatchQuantity = SalesOrderBatchStore.getQuantity(props.order_item_id);
        const sampleQuantity = line_item.quantity_sample_required || 0;
        const lineItemQuantity = line_item.quantity || 0;
        if (sampleQuantity > 0) {
            const totalAllocated = totalBatchQuantity + sampleQuantity;
            return Math.max(0, lineItemQuantity - totalAllocated);
        }
        return Math.max(0, lineItemQuantity - totalBatchQuantity);
    };
    const cachedMaxQty = useMemo(() => getMaxQuantity(), [
        line_item?.quantity,
        line_item?.quantity_sample_required,
        props?.order_item_id,
        SalesOrderBatchStore.total_quantity,
    ]);
    return (React.createElement("div", { style: { border: '1px solid #E7E6E6' }, className: 'p-4 mt-8' },
        React.createElement(Form, { layout: "inline", onSubmit: handleSubmitBatch },
            React.createElement(Form.Item, { label: "Estimated Ship Date" }, getFieldDecorator('batch_ship_part_date', {
                rules: [
                    {
                        required: true,
                        message: 'Please select a date!',
                    },
                ],
            })(React.createElement(DatePicker, { disabledDate: handleDisabledDates }))),
            React.createElement(Form.Item, { label: "Quantity", hasFeedback: true, validateStatus: cachedMaxQty == 0 ? 'error' : '', help: cachedMaxQty == 0 && 'No quantity remains' }, getFieldDecorator('batch_quantity', {
                rules: [
                    {
                        required: true,
                        message: 'Please input a quantity!',
                    },
                ],
            })(React.createElement(InputNumber, { max: cachedMaxQty, placeholder: String(SalesOrderBatchStore.missing_quantity), style: { width: '100px' } }))),
            React.createElement(Form.Item, null,
                React.createElement(Button, { type: "primary", htmlType: "submit" }, "Add")))));
});
export default Form.create()(SalesOrderAddBatchForm);
