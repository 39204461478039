import { action, makeObservable, observable, runInAction } from 'mobx';
import { get } from '@partsbadger/utils';

export interface IShipment {
    id: number;
    sales_order: null;
    number: string;
    location: string;
    outbound_destination: null;
    printout: null;
    z_process: boolean;
    z_processes_name: null;
    acknowledge: boolean;
    shipping_designation: null;
    po_selected_for_outtake: null;
    line_items: IShipmentLineItem[];
    box: IBox;
}

export interface IBox {
    shipment_id: null;
    status: string;
    sales_order_outtake: number;
    recipient_name: string;
    recipient_street: string;
    recipient_city: string;
    recipient_state: string;
    recipient_zip_code: string;
    recipient_country: string;
    recipient_phone: string;
    carrier: string;
    destination: string;
    account_type: string;
    shipping_account: string;
    shipping_method: string;
    shipping_cost_carrier: string;
    shipping_cost: string;
    late_shipping_cost: string;
    bill_to_customer: boolean;
    is_late_shipment: boolean;
    boxes_included: number;
    tracking_number: string;
    notes: string;
    notification_delivered: boolean;
    internal_notes: null;
    label: null;
    template: null;
    notification_opt_out: boolean;
}

export interface IShipmentLineItem {
    id: number;
    sales_order_lineitem: {
        id: number;
        name: string;
    };
    quantity: number;
    zoho_id: null;
}

class Shipment {
    isLoading = false;
    entity: IShipment | null = null;
    constructor() {
        makeObservable(this, {
            // Observables
            isLoading: observable,
            entity: observable,

            // Actions
            getById: action,
        });
    }

    async getById(id: number | string) {
        runInAction(() => {
            this.isLoading = true;
        });

        const data = await get(`/staff/outtakes/${id}/`);

        runInAction(() => {
            this.entity = data;
            this.isLoading = false;
        });

        return data;
    }
}

export const ShipmentStore = new Shipment();
