import React, { useState } from 'react';
import '@ant-design/compatible/assets/index.css';
import { useHistory } from 'react-router-dom';
import { Dropdown, Menu, Modal, notification, Popconfirm } from 'antd';
import { hasGroup, hasPermission } from '../User';
import {
    CheckCircleOutlined,
    CheckOutlined,
    CloseOutlined,
    CopyOutlined,
    DollarOutlined,
    DownloadOutlined,
    EyeOutlined,
    FilePdfOutlined,
    LoadingOutlined,
    MinusCircleOutlined,
    RedoOutlined,
    SendOutlined,
    ShoppingCartOutlined,
    StopOutlined,
    SyncOutlined,
} from '@ant-design/icons';
import { SalesOrderStore } from '../../stores/SalesOrderStore';
import { get, post } from '../../shared';
import { remove } from '@partsbadger/utils';
import { SalesOrderPreview } from './SalesOrderPreview';
import { ErrorRender, UploadRedactedFilesForm } from '@partsbadger/library';
import RequestOrderAdjustment from './RequestOrderAdjustment';
import CloneOrderModal from './CloneOrderModal';
import RequestCancelationHold from './RequestCancelationHold';
import { SalesOrderListProps } from '@partsbadger/types';

const FileDownload = require('js-file-download');

interface Props {
    record: SalesOrderListProps;
    handleReloadOrders?: () => void;
}
const OrderListActions = (props: Props) => {
    const { record, handleReloadOrders } = props;
    const [loading, setLoading] = useState(false);
    const [order, setOrder] = useState<{ id: string; line_items: [] } | null>(null);
    const [previewOrder, setPreviewOrder] = useState<number | null>(null);

    const [downloading, setDownloading] = useState<boolean>(false);
    const [showRequestCancelationHoldModal, setShowRequestCancelationHoldModal] = useState<boolean>(false);
    const [showUploadFiles, setShowUploadFiles] = useState<boolean>(false);
    const [showRequestOrderAdjustment, setShowRequestOrderAdjustment] = useState<boolean>(false);
    const [showCloneOrderModal, setShowCloneOrderModal] = useState<boolean>(false);

    const downloadPdf = (order_id: number, order_name: string) => {
        setLoading(true);

        get(`staff/sales-orders/${order_id}/download/`, {}, { responseType: 'blob' })
            .then((response: any) => {
                const data = response.data;

                FileDownload(data, `${order_name}.pdf`);
                setLoading(false);
                notification.success({ message: 'Document downloaded' });
            })
            .catch((error: any) => notification.error(error));
    };

    const download = (order_id: number, order_name: string) => {
        if (order_id) {
            setDownloading(true);
            SalesOrderStore.downloadAllFiles(order_id).finally(() => setDownloading(false));
        }
    };

    const getOrdersById = async (id: number) => {
        setLoading(true);
        await get(`staff/sales-orders/${id}/`)
            .then((response: any) => {
                setOrder(response.data);
            })
            .finally(() => setLoading(false));
    };

    const getRequestCancelationOrHoldModal = (orderId: number) => {
        getOrdersById(orderId);
        setShowRequestCancelationHoldModal(true);
    };

    const deleteOrder = (id: number) => {
        setLoading(true);
        remove(`/staff/sales-orders/${id}/`)
            .then((response: any) => {
                if (handleReloadOrders) {
                    handleReloadOrders();
                }
            })
            .finally(() => setLoading(false));
    };

    const history = useHistory();

    const parts = order?.line_items || [];

    const frequent_action = (
        <>
            {record.status != 'Draft' && hasPermission('salesorders.change_salesorder') ? (
                <div>
                    <a
                        type="link"
                        onClick={() => {
                            history.push(`/sales-order/${record.id}/`);
                        }}
                    >
                        Review Order
                    </a>
                </div>
            ) : null}

            {record.status != 'Draft' && (
                <div>
                    <a
                        type="link"
                        onClick={() => {
                            history.push(`/orders/redaction-tool/${record.id}`);
                        }}
                    >
                        Redaction Tool
                    </a>
                </div>
            )}
        </>
    );

    const menu = (
        <Menu>
            {record.status === 'Draft' ? (
                <Menu.Item
                    onClick={async () => {
                        history.push(`/sales-order/new/?DraftOrderID=${record.id}`);
                    }}
                    icon={<EyeOutlined />}
                >
                    Continue order
                </Menu.Item>
            ) : (
                <>
                    <Menu.Item
                        onClick={() => {
                            history.push(`/new-sales-order-detail/${record.id}`);
                        }}
                        icon={<EyeOutlined />}
                    >
                        Order Details
                    </Menu.Item>

                    {/*<Menu.Item*/}
                    {/*    onClick={() => {*/}
                    {/*        history.push(`/sales-order-detail/${record.id}`);*/}
                    {/*    }}*/}
                    {/*    icon={<EyeOutlined />}*/}
                    {/*>*/}
                    {/*    View Order*/}
                    {/*</Menu.Item>*/}

                    {hasPermission('salesorders.change_salesorder') && (
                        <Menu.Item
                            onClick={() => {
                                history.push(`/sales-order/${record.id}/`);
                            }}
                            icon={<CheckCircleOutlined />}
                        >
                            Review Order
                        </Menu.Item>
                    )}
                    <Menu.Item
                        onClick={async () => {
                            if (!SalesOrderStore.order?.id || SalesOrderStore.order?.id !== record.id) {
                                await SalesOrderStore.getById(record.id);
                            }

                            SalesOrderStore.setCloneOrder();
                            setShowCloneOrderModal(true);
                        }}
                        icon={<CopyOutlined />}
                    >
                        Clone Order
                    </Menu.Item>

                    {hasPermission('salesorders.change_salesorder') && (
                        <Menu.Item
                            // onClick={() => {
                            //     history.push(`/sales-order/${record.id}/approved-sample`);
                            // }}
                            onClick={async () => {
                                if (!SalesOrderStore.order?.id || SalesOrderStore.order?.id !== record.id) {
                                    await SalesOrderStore.getById(record.id);
                                }

                                setShowRequestOrderAdjustment(true);
                            }}
                            icon={<CheckOutlined />}
                        >
                            Submit Sample Approval/Rejection
                        </Menu.Item>
                    )}

                    {record.zoho_id && (
                        <Menu.Item
                            // onClick={() => {
                            //     history.push(
                            //         `/sales-order/${record.id}/request-design-change`
                            //     );
                            // }}
                            onClick={async () => {
                                if (!SalesOrderStore.order?.id || SalesOrderStore.order?.id !== record.id) {
                                    await SalesOrderStore.getById(record.id);
                                }

                                setShowRequestOrderAdjustment(true);
                            }}
                            icon={<SendOutlined />}
                        >
                            Request Design Change
                        </Menu.Item>
                    )}

                    {record.status !== 'Needs Review' && (
                        <Menu.Item
                            onClick={() => {
                                setPreviewOrder(record.id);
                            }}
                            icon={<FilePdfOutlined />}
                        >
                            Preview
                        </Menu.Item>
                    )}

                    {record.status !== 'Needs Review' && (
                        <Menu.Item
                            onClick={() => {
                                downloadPdf(record.id, record.name);
                            }}
                            icon={<FilePdfOutlined />}
                        >
                            Download PDF
                        </Menu.Item>
                    )}

                    <Menu.Item
                        onClick={() => {
                            history.push(`/sales-orders/${record.id}/dwg`);
                        }}
                        icon={<FilePdfOutlined />}
                    >
                        DWG
                    </Menu.Item>

                    {hasPermission('salesorders.add_salesorder') && (
                        <Menu.Item
                            onClick={() => download(record.id, record.name)}
                            icon={downloading ? <LoadingOutlined /> : <DownloadOutlined />}
                        >
                            Download all Required Paperwork
                        </Menu.Item>
                    )}

                    {hasPermission('salesorders.change_salesorder') && record.status === 'Ready for Production' && (
                        <Menu.Item
                            onClick={() => {
                                setLoading(true);
                                getOrdersById(record.id);
                                setShowUploadFiles(true);
                            }}
                            icon={<CheckCircleOutlined />}
                        >
                            Upload Files
                        </Menu.Item>
                    )}

                    {record.status !== 'Needs Review' && (
                        <Menu.Item
                            onClick={() => {
                                history.push(`/sales-order/${record.id}/credit-memos`);
                            }}
                            icon={<DollarOutlined />}
                        >
                            Credit Memos
                        </Menu.Item>
                    )}

                    {record.zoho_id ? (
                        <Menu.Item
                            onClick={() => {
                                history.push(`/sales-order/${record.zoho_id}/rework`);
                            }}
                            icon={<RedoOutlined />}
                        >
                            Create Rework
                        </Menu.Item>
                    ) : null}

                    {hasPermission('salesorders.change_salesorder') ||
                    hasPermission('salesorders.change_own_salesorder') ? (
                        <Menu.Item
                            onClick={async () => {
                                if (!SalesOrderStore.order?.id || SalesOrderStore.order?.id !== record.id) {
                                    await SalesOrderStore.getById(record.id);
                                }

                                setShowRequestOrderAdjustment(true);
                            }}
                            icon={<StopOutlined />}
                        >
                            Request Cancelation or Hold
                        </Menu.Item>
                    ) : null}
                </>
            )}

            {record.zoho_id && (
                <Menu.Item
                    onClick={() => {
                        setLoading(true);
                        post(`staff/sales-orders/${record.id}/send-to-vendor-app/`)
                            .then(() => {
                                notification.success({
                                    message: 'Done',
                                    description: 'Synced with vendor app',
                                    placement: 'bottomRight',
                                    duration: 5,
                                });
                            })
                            .catch((e: any) => {
                                notification.error({
                                    message: 'We could sync with vendor app',
                                    description: <ErrorRender error={e} />,
                                    placement: 'bottomRight',
                                    duration: 5,
                                });
                            })
                            .finally(() => {
                                setLoading(false);
                            });
                    }}
                    icon={<FilePdfOutlined />}
                >
                    Sync With Vendor
                </Menu.Item>
            )}

            {record.status != 'Draft' && (
                <Menu.Item
                    onClick={() => {
                        history.push(`/orders/redaction-tool/${record.id}`);
                    }}
                    icon={<FilePdfOutlined />}
                >
                    Redaction Tool
                </Menu.Item>
            )}

            {/* {hasPermission('salesorders.order_shop') && ( */}
            <Menu.Item
                onClick={() => {
                    history.push(`/sales-orders/order-shop/${record.id}`);
                }}
                icon={<ShoppingCartOutlined />}
            >
                Send to Shop
            </Menu.Item>
            {/* )} */}

            {hasPermission('salesorders.delete_salesorder') && !record.zoho_id && (
                <Menu.Item icon={<CloseOutlined />}>
                    <Popconfirm
                        placement="topLeft"
                        title={'Do you want to remove this order?'}
                        onConfirm={() => {
                            deleteOrder(record.id);
                        }}
                        okText="Yes"
                        cancelText="No"
                    >
                        Delete
                    </Popconfirm>
                </Menu.Item>
            )}
            {hasGroup('Production') && !['Shipped in Full', 'Shipped & Paid', 'Completed'].includes(record.status) && (
                <Menu.Item
                    onClick={() => {
                        history.push(`/new-sales-order-detail/${record.id}/cancellation`);
                    }}
                    icon={<MinusCircleOutlined />}
                >
                    Cancel Items
                </Menu.Item>
            )}
            {record.zoho_id && (
                <Menu.Item
                    onClick={() => {
                        setLoading(true);
                        post(`staff/sales-orders/${record.id}/send-requirements-to-vendor-app/`)
                            .then(() => {
                                notification.success({
                                    message: 'Done',
                                    description: 'The requirements were sent.',
                                    placement: 'bottomRight',
                                    duration: 5,
                                });
                            })
                            .catch((e: any) => {
                                notification.error({
                                    message: 'We could sync with vendor app',
                                    description: <ErrorRender error={e} />,
                                    placement: 'bottomRight',
                                    duration: 5,
                                });
                            })
                            .finally(() => {
                                setLoading(false);
                            });
                    }}
                    icon={<SyncOutlined />}
                >
                    Sync Vendor Requirements
                </Menu.Item>
            )}

            {hasGroup('Shipping/Receiving') && record.zoho_id && (
                <Menu.Item
                    key="shipping"
                    onClick={() => {
                        history.push(`/shipping-receiving/?id=${record.id}&type=sales-order`);
                    }}
                    icon={<SendOutlined />}
                >
                    View in Shipping App
                </Menu.Item>
            )}
        </Menu>
    );

    return (
        <>
            {frequent_action}
            <Dropdown overlay={menu}>
                <span className={'link flex flex-row items-center'}>
                    Actions <DownloadOutlined />
                </span>
            </Dropdown>

            {/*  Modals*/}
            {previewOrder && <SalesOrderPreview order_id={previewOrder} handleClose={() => setPreviewOrder(null)} />}

            <Modal
                open={showUploadFiles}
                onCancel={() => {
                    setOrder(null);
                }}
                footer={[]}
            >
                {order?.id && (
                    <div className={'p-4'}>
                        <UploadRedactedFilesForm
                            parts={parts}
                            onUploadRedactedFile={async (line_item_id, file_type, file) => {
                                const formData = new FormData();
                                const filename = file_type === '2d' ? 'redacted_2d_file' : 'redacted_3d_file';

                                formData.append('line_item', line_item_id);
                                formData.append(filename, file);

                                // await post(`staff/sales-orders/${order.id}/redacted-files/`, formData, {}, true);
                            }}
                        />
                    </div>
                )}
            </Modal>

            {showRequestOrderAdjustment && SalesOrderStore.order?.id && (
                <RequestOrderAdjustment
                    onClose={() => setShowRequestOrderAdjustment(false)}
                    order={SalesOrderStore.order}
                    showRequestOrderAdjustment={showRequestOrderAdjustment}
                />
            )}

            {showCloneOrderModal && SalesOrderStore.order?.id && (
                <CloneOrderModal
                    onClose={() => setShowCloneOrderModal(false)}
                    showCloneOrderModal={showCloneOrderModal}
                />
            )}

            <Modal
                open={showRequestCancelationHoldModal}
                onCancel={() => {
                    setShowRequestCancelationHoldModal(false);
                }}
                footer={[]}
                title="Request Cancelation or Hold"
            >
                <RequestCancelationHold orderData={order} onCancel={() => setShowRequestCancelationHoldModal(false)} />
            </Modal>
        </>
    );
};

export default OrderListActions;
