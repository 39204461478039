import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Skeleton } from 'antd';
import { observer } from 'mobx-react';
import { BadgerPage } from '../../components/badger-ui';

import { useParams } from 'react-router-dom';
import { ShipmentStore } from '../../stores/ShipmentStore';
import ShipmentDetails from '../../components/Shipments/ShipmentDetails';

const ShipmentDetailPage = () => {
    const history = useHistory();
    const { outtake_id } = useParams<{ outtake_id: string }>();
    const [selectedSection, setSelectedSection] = useState('basicInfo');
    useEffect(() => {
        if (outtake_id) {
            ShipmentStore.getById(outtake_id);
        }
    }, [outtake_id]);

    const { entity, isLoading } = ShipmentStore;

    const renderSectionContent = () => {
        if (!entity) {
            return <Skeleton active />;
        }
        switch (selectedSection) {
            case 'basicInfo':
                return (
                    <>
                        <ShipmentDetails shipment={entity} />
                    </>
                );

            default:
                return null;
        }
    };

    return (
        <BadgerPage
            title={entity?.id ?? '...'}
            isLoading={isLoading}
            handleBack={() => {
                history.goBack();
            }}
            // breadcrumbs={[{ title: 'Shipments', link: '/shipments' }, { title: entity?.id ?? '...' }]}
            // sidebarLinks={
            //     account ? [{ title: 'Basic Information', link: 'basicInfo', icon: <InfoCircleOutlined /> }] : []
            // }
            // handleSidebarClick={key => setSelectedSection(key)}
            topActions={[]}
        >
            {renderSectionContent()}
        </BadgerPage>
    );
};

export default observer(ShipmentDetailPage);
