import React, { useEffect, useState } from 'react';
import { get, useQueryParameters } from '@partsbadger/utils';
import { Col, Divider, Input, Row, Table, Tag, Typography } from 'antd';
import {
    IContactListStaff,
    IInvoiceListStaff,
    IOuttakeListStaff,
    IQuoteListStaff,
    ISalesOrderListStaff,
} from '@partsbadger/types';
import { IPartList } from '../../stores/PartsStore';

import { ColumnProps } from 'antd/es/table';
import { DateRender, Price } from '@partsbadger/library';
import { useHistory } from 'react-router';
import { IAccountList } from '../../stores/AccountStore';
import OrderListActions from '../../components/SalesOrder/OrderListActions';
import { ISalesOrderLineItem } from '../../stores/SalesOrderLineItemStore';
import QuoteMenuActions from '../Quote/QuoteMenuActions';
import ContactListActions from '../../components/Contact/ContactListActions';

const SearchSalesOrders = (props: { query: string }) => {
    const { query } = props;
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    const [data, setData] = useState<ISalesOrderListStaff[]>([]);

    const loadOrders = () => {
        get('/staff/sales-orders/', {
            params: {
                search: query,
                page_size: 5,
            },
        })
            .then(data => {
                setData(data.results);
            })
            .finally(() => setLoading(false));
    };
    useEffect(() => {
        setLoading(true);
        loadOrders();

        return () => {};
    }, [query]);

    const columns: ColumnProps<ISalesOrderListStaff>[] = [
        {
            title: 'Name',
            dataIndex: 'name',
            render: (text, record) => {
                return (
                    <div>
                        <Typography.Link
                            disabled={record?.status == 'Draft'}
                            onClick={() => history.push(`/new-sales-order-detail/${record?.id}/`)}
                        >
                            {record?.name}
                        </Typography.Link>
                        <br />
                        <Tag>{record.status}</Tag>
                    </div>
                );
            },
        },
        {
            title: 'Customer',
            dataIndex: 'customer',
            render: (text, record) => {
                return (
                    <div>
                        <Typography.Link
                            onClick={() => {
                                history.push(`/accounts/${record.account?.id}/`);
                            }}
                        >
                            {record.account?.name}
                        </Typography.Link>
                        <br />
                        <Typography.Link
                            onClick={() => {
                                history.push(`/contacts/${record.contact?.id}/`);
                            }}
                        >
                            {record.contact?.fullname}
                        </Typography.Link>
                    </div>
                );
            },
        },
        {
            title: 'Date',
            dataIndex: 'created_time',
            render: (text, record) => {
                return (
                    <div>
                        <div>{<DateRender value={record?.created_time} />}</div>
                        <div className={'italic'}>By {record.created_by.fullname}</div>
                    </div>
                );
            },
        },
        {
            title: 'Grand Total',
            dataIndex: 'grand_total',
            render: (text, record) => {
                return <Price value={record.grand_total} />;
            },
        },

        {
            title: 'Action',
            dataIndex: 'id',
            render: (text, record) => {
                return <OrderListActions record={record} handleReloadOrders={loadOrders} />;
            },
        },
    ];

    return (
        <Row>
            <Divider>
                <div className={'title'}> Sales Orders</div>
            </Divider>

            <Row>
                <Col span={24}>
                    <Table<ISalesOrderListStaff>
                        rowKey={'id'}
                        style={{ width: 1200 }}
                        dataSource={data}
                        loading={loading}
                        columns={columns}
                        pagination={false}
                        scroll={{ x: 'auto' }}
                        locale={{
                            emptyText: 'No records found',
                        }}
                    ></Table>
                </Col>
            </Row>
        </Row>
    );
};

const SearchQuotes = (props: { query: string }) => {
    const { query } = props;
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    const [data, setData] = useState<IQuoteListStaff[]>([]);

    useEffect(() => {
        setLoading(true);
        get('/staff/v2/quotes/', {
            params: {
                search: query,
                page_size: 5,
            },
        })
            .then(data => {
                setData(data.results);
            })
            .finally(() => setLoading(false));
        return () => {};
    }, [query]);

    const columns: ColumnProps<IQuoteListStaff>[] = [
        {
            title: 'Name',
            dataIndex: 'name',
            render: (text, record) => {
                return (
                    <div>
                        <Typography.Link
                            onClick={() => {
                                if (record.name) {
                                    history.push(`/quotes/${record.id}/details/`);
                                }
                            }}
                        >
                            {record.name}
                        </Typography.Link>

                        <br />

                        <Tag>{record.stage}</Tag>
                    </div>
                );
            },
        },
        {
            title: 'Customer',
            dataIndex: 'customer',
            render: (text, record) => {
                return (
                    <div>
                        <Typography.Link
                            onClick={() => {
                                history.push(`/accounts/${record.account?.id}/`);
                            }}
                        >
                            {record.account?.name}
                        </Typography.Link>
                    </div>
                );
            },
        },

        {
            title: 'Created Time',
            dataIndex: 'created_time',
            render: (text, record) => {
                return (
                    <div>
                        <div>{<DateRender value={record?.created_time} />}</div>
                        <div className={'italic'}>By {record.created_by.fullname}</div>
                    </div>
                );
            },
        },
        {
            title: 'Grand Total',
            dataIndex: 'grand_total',
            render: (text, record) => {
                return <Price value={record.grand_total} />;
            },
        },

        {
            title: 'Action',
            dataIndex: 'id',
            render: (text, quote) => {
                return (
                    <QuoteMenuActions
                        quote={{
                            id: quote.id,
                            name: quote.name,
                            completed: quote.completed,
                            deal: quote.deal,
                        }}
                        rfq={null}
                        handleRefresh={() => {
                            // loadRfqs();
                        }}
                    />
                );
            },
        },
    ];

    return (
        <Row>
            <Divider>
                <div className={'title'}> Quotes</div>
            </Divider>

            <Row>
                <Col span={24}>
                    <Table<IQuoteListStaff>
                        rowKey={'id'}
                        style={{ width: 1200 }}
                        dataSource={data}
                        loading={loading}
                        columns={columns}
                        pagination={false}
                        scroll={{ x: 'auto' }}
                        locale={{
                            emptyText: 'No records found',
                        }}
                    ></Table>
                </Col>
            </Row>
        </Row>
    );
};

const SearchQuotedParts = (props: { query: string }) => {
    const { query } = props;
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    const [data, setData] = useState<IPartList[]>([]);

    useEffect(() => {
        setLoading(true);
        get('/staff/v2/quoted-products/', {
            params: {
                search: query,
                page_size: 5,
            },
        })
            .then(data => {
                setData(data.results);
            })
            .finally(() => setLoading(false));
        return () => {};
    }, [query]);

    const columns: ColumnProps<IPartList>[] = [
        {
            title: 'Name',
            dataIndex: 'name',
            render: (text, record) => {
                return record.master_product?.name;
            },
        },

        {
            title: 'Quote',
            dataIndex: 'quote',
            render: (text, record) => {
                return (
                    <div>
                        <Typography.Link onClick={() => history.push(`/quotes/${record.quote?.id}/details`)}>
                            {record.quote?.name}
                        </Typography.Link>
                    </div>
                );
            },
        },
        {
            title: 'Stage',
            dataIndex: 'stage',
            render: (text, record) => {
                return <Tag>{record.quote?.stage}</Tag>;
            },
        },

        {
            title: 'Created Time',
            dataIndex: 'created_time',
            render: (text, record) => {
                return (
                    <div>
                        <div>{<DateRender value={record.created_time} />}</div>
                    </div>
                );
            },
        },
    ];

    return (
        <Row>
            <Divider>
                <div className={'title'}> Quoted Parts</div>
            </Divider>

            <Row>
                <Col span={24}>
                    <Table<IPartList>
                        rowKey={'id'}
                        style={{ width: 1200 }}
                        dataSource={data}
                        loading={loading}
                        columns={columns}
                        pagination={false}
                        scroll={{ x: 'auto' }}
                        locale={{
                            emptyText: 'No records found',
                        }}
                    ></Table>
                </Col>
            </Row>
        </Row>
    );
};

const SearchInvoices = (props: { query: string }) => {
    const { query } = props;
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    const [data, setData] = useState<IInvoiceListStaff[]>([]);

    useEffect(() => {
        setLoading(true);
        get('/staff/invoices/', {
            params: {
                search: query,
                page_size: 5,
            },
        })
            .then(data => {
                setData(data.results);
            })
            .finally(() => setLoading(false));
        return () => {};
    }, [query]);

    const columns: ColumnProps<IInvoiceListStaff>[] = [
        {
            title: 'Name',
            dataIndex: 'name',
            render: (text, record) => {
                return (
                    <Typography.Link onClick={() => history.push(`/invoices/${record.id}/`)}>
                        {record.name}
                    </Typography.Link>
                );
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (text, record) => {
                return <Tag>{record.invoice_status}</Tag>;
            },
        },
        {
            title: 'Customer',
            dataIndex: 'customer',
            render: (text, record) => {
                return (
                    <div>
                        <Typography.Link
                            onClick={() => {
                                history.push(`/accounts/${record.account?.id}/`);
                            }}
                        >
                            {record.account?.name}
                        </Typography.Link>
                    </div>
                );
            },
        },

        {
            title: 'Created Time',
            dataIndex: 'created_time',
            render: (text, record) => {
                return (
                    <div>
                        <div>{<DateRender value={record.created_time} />}</div>
                    </div>
                );
            },
        },
        {
            title: 'Grand Total',
            dataIndex: 'grand_total',
            render: (text, record) => {
                return <Price value={record.grand_total} />;
            },
        },
    ];

    return (
        <Row>
            <Divider>
                <div className={'title'}> Invoices</div>
            </Divider>

            <Row>
                <Col span={24}>
                    <Table<IInvoiceListStaff>
                        rowKey={'id'}
                        style={{ width: 1200 }}
                        dataSource={data}
                        loading={loading}
                        columns={columns}
                        pagination={false}
                        scroll={{ x: 'auto' }}
                        locale={{
                            emptyText: 'No records found',
                        }}
                    ></Table>
                </Col>
            </Row>
        </Row>
    );
};

const SearchOrderedParts = (props: { query: string }) => {
    const { query } = props;
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    const [data, setData] = useState<ISalesOrderLineItem[]>([]);

    useEffect(() => {
        setLoading(true);
        get('/staff/sales-order-line-items/', {
            params: {
                search: query,
                page_size: 5,
            },
        })
            .then(data => {
                setData(data.results);
            })
            .finally(() => setLoading(false));
        return () => {};
    }, [query]);

    const columns: ColumnProps<ISalesOrderLineItem>[] = [
        {
            title: 'Name',
            dataIndex: 'name',
            render: (text, record) => {
                return (
                    <Typography.Link onClick={() => history.push(`/sales-order-line-items/${record.id}/`)}>
                        {record.name}
                    </Typography.Link>
                );
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (text, record) => {
                return <Tag>{record.status}</Tag>;
            },
        },
        {
            title: 'Sales Order',
            dataIndex: 'sales_order',
            render: (text, record) => {
                return (
                    <Typography.Link onClick={() => history.push(`/new-sales-order-detail/${record.sales_order?.id}/`)}>
                        {record.sales_order?.name}
                    </Typography.Link>
                );
            },
        },

        {
            title: 'Created Time',
            dataIndex: 'created_time',
            render: (text, record) => {
                return (
                    <div>
                        <div>{<DateRender value={record.created_time} />}</div>
                    </div>
                );
            },
        },
    ];

    return (
        <Row>
            <Divider>
                <div className={'title'}> Ordered Parts</div>
            </Divider>

            <Row>
                <Col span={24}>
                    <Table<ISalesOrderLineItem>
                        rowKey={'id'}
                        style={{ width: 1200 }}
                        dataSource={data}
                        loading={loading}
                        columns={columns}
                        pagination={false}
                        scroll={{ x: 'auto' }}
                        locale={{
                            emptyText: 'No records found',
                        }}
                    ></Table>
                </Col>
            </Row>
        </Row>
    );
};

const SearchContacts = (props: { query: string }) => {
    const { query } = props;
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    const [data, setData] = useState<IContactListStaff[]>([]);

    useEffect(() => {
        setLoading(true);
        get('/staff/contacts/', {
            params: {
                search: query,
                page_size: 5,
            },
        })
            .then(data => {
                setData(data.results);
            })
            .finally(() => setLoading(false));
        return () => {};
    }, [query]);

    const columns: ColumnProps<IContactListStaff>[] = [
        {
            title: 'First Name',
            dataIndex: 'name',
            render: (text, record) => {
                return (
                    <div>
                        <Typography.Link
                            onClick={() => {
                                history.push(`/contacts/${record.id}/`);
                            }}
                        >
                            {record.fullname}
                        </Typography.Link>
                    </div>
                );
            },
        },

        {
            title: 'Account',
            dataIndex: 'account',
            render: (text, record) => {
                return (
                    <div>
                        <Typography.Link
                            onClick={() => {
                                history.push(`/accounts/${record.account?.id}/`);
                            }}
                        >
                            {record.account?.name}
                        </Typography.Link>
                    </div>
                );
            },
        },

        {
            title: 'Email',
            dataIndex: 'email',
            render: (text, record) => {
                return (
                    <div>
                        <div>{record.email}</div>
                    </div>
                );
            },
        },

        {
            title: 'Date',
            dataIndex: 'created_time',
            render: (text, record) => {
                return (
                    <div>
                        <div>{<DateRender value={record.created_time} />}</div>
                        <div className={'italic'}>By {record.owner.fullname}</div>
                    </div>
                );
            },
        },

        {
            title: 'Action',
            dataIndex: 'id',
            render: (text, record) => {
                return <ContactListActions contact={record} />;
            },
        },
    ];
    return (
        <Row>
            <Divider>
                <div className={'title'}> Contacts </div>
            </Divider>

            <Row>
                <Col span={24}>
                    <Table<IContactListStaff>
                        rowKey={'id'}
                        style={{ width: 1200 }}
                        dataSource={data}
                        loading={loading}
                        columns={columns}
                        pagination={false}
                        scroll={{ x: 'auto' }}
                        locale={{
                            emptyText: 'No records found',
                        }}
                    ></Table>
                </Col>
            </Row>
        </Row>
    );
};

const SearchAccounts = (props: { query: string }) => {
    const { query } = props;
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    const [data, setData] = useState<IAccountList[]>([]);

    useEffect(() => {
        setLoading(true);
        get('/staff/accounts/', {
            params: {
                search: query,
                page_size: 5,
            },
        })
            .then(data => {
                setData(data.results);
            })
            .finally(() => setLoading(false));
        return () => {};
    }, [query]);

    const columns: ColumnProps<IAccountList>[] = [
        {
            title: 'Name',
            dataIndex: 'name',
            render: (text, record) => {
                return (
                    <div>
                        <Typography.Link
                            onClick={() => {
                                history.push(`/accounts/${record.id}/`);
                            }}
                        >
                            {record.name}
                        </Typography.Link>
                    </div>
                );
            },
        },
        {
            title: 'Date',
            dataIndex: 'created_time',
            render: (text, record) => {
                return (
                    <div>
                        <div>{<DateRender value={record.created_time} />}</div>
                        <div className={'italic'}>By {record.owner.fullname}</div>
                    </div>
                );
            },
        },

        {
            title: 'Action',
            dataIndex: 'id',
            render: (text, record) => {
                return (
                    <div>
                        <a className={'cursor-pointer'} onClick={() => history.push('/accounts/' + record.id)}>
                            View
                        </a>
                    </div>
                );
            },
        },
    ];
    return (
        <Row>
            <Divider>
                <div className={'title'}> Accounts </div>
            </Divider>

            <Row>
                <Col span={24}>
                    <Table<IAccountList>
                        rowKey={'id'}
                        style={{ width: 1200 }}
                        dataSource={data}
                        loading={loading}
                        columns={columns}
                        pagination={false}
                        scroll={{ x: 'auto' }}
                        locale={{
                            emptyText: 'No records found',
                        }}
                    ></Table>
                </Col>
            </Row>
        </Row>
    );
};
const CustomerShipments = (props: { query: string }) => {
    const { query } = props;
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    const [data, setData] = useState<IOuttakeListStaff[]>([]);

    useEffect(() => {
        setLoading(true);
        get('/staff/outtakes/', {
            params: {
                search: query,
                page_size: 5,
            },
        })
            .then(data => {
                setData(data.results);
            })
            .finally(() => setLoading(false));
        return () => {};
    }, [query]);

    const columns: ColumnProps<IOuttakeListStaff>[] = [
        {
            title: 'id',
            dataIndex: 'id',
            render: (text, record) => {
                return <div>{record.id}</div>;
            },
        },

        {
            title: 'Sales Order',
            dataIndex: 'sales_order',
            render: (text, record) => {
                return (
                    <div>
                        <Typography.Link
                            onClick={() => history.push(`/new-sales-order-detail/${record.sales_order?.id}/`)}
                        >
                            {record.sales_order?.name}
                        </Typography.Link>
                    </div>
                );
            },
        },

        {
            title: 'Date',
            dataIndex: 'created_time',
            render: (text, record) => {
                return (
                    <div>
                        <div>{<DateRender value={record.created_time} />}</div>
                        <div className={'italic'}>By {record.created_by.fullname}</div>
                    </div>
                );
            },
        },

        {
            title: 'Destination',
            dataIndex: 'outbound_destination',
            key: 'outbound_destination',
        },

        {
            title: 'Action',
            dataIndex: 'id',
            render: (text, record) => {
                return (
                    <div>
                        <a
                            className={'cursor-pointer'}
                            onClick={() => {
                                history.push(`/shipping-receiving/?id=${record.sales_order?.id}&type=sales-order`);
                            }}
                        >
                            View Shipment
                        </a>
                    </div>
                );
            },
        },
    ];
    return (
        <Row>
            <Divider>
                <div className={'title'}> Shipments </div>
            </Divider>

            <Row>
                <Col span={24}>
                    <Table<IOuttakeListStaff>
                        rowKey={'id'}
                        style={{ width: 1200 }}
                        dataSource={data}
                        loading={loading}
                        columns={columns}
                        pagination={false}
                        scroll={{ x: 'auto' }}
                        locale={{
                            emptyText: 'No records found',
                        }}
                    ></Table>
                </Col>
            </Row>
        </Row>
    );
};

const SearchPage = () => {
    const history = useHistory();
    const queryParams = useQueryParameters();
    const query = queryParams.get('query') || '';

    return (
        <div
            style={{
                maxWidth: '1200px',
                margin: '0 auto',
            }}
        >
            <Row>
                <Col md={24}>
                    <Typography.Title level={2}>Search Page</Typography.Title>
                </Col>
            </Row>

            <Row>
                <Col md={24}>
                    <div className={'flex flex-row items-center'}>
                        Search:{' '}
                        <Input.Search
                            defaultValue={query}
                            placeholder={'Search'}
                            onSearch={v => {
                                history.push(`/search?query=${v}`);
                            }}
                        />
                    </div>
                </Col>
            </Row>

            {query ? (
                <>
                    <Row>
                        <Col md={24} lg={24}>
                            <SearchAccounts query={query} />
                        </Col>
                        <Col md={24} lg={24}>
                            <SearchContacts query={query} />
                        </Col>
                        <Col md={24} lg={24}>
                            <SearchSalesOrders query={query} />
                        </Col>
                        <Col md={24} lg={24}>
                            <SearchOrderedParts query={query} />
                        </Col>
                        <Col md={24} lg={24}>
                            <CustomerShipments query={query} />
                        </Col>
                        <Col md={24} lg={24}>
                            <SearchQuotes query={query} />
                        </Col>

                        <Col md={24} lg={24}>
                            <SearchQuotedParts query={query} />
                        </Col>
                        <Col md={24} lg={24}>
                            <SearchInvoices query={query} />
                        </Col>
                    </Row>
                </>
            ) : (
                <div className={'flex flex-col items-center justify-center mt-10'}>
                    Please enter search query to search
                </div>
            )}
        </div>
    );
};

export default SearchPage;
