import React from 'react';
import { Empty, Typography } from 'antd';
import { SalesOrderStore } from '../../../stores/SalesOrderStore';
import { ColumnProps } from 'antd/es/table';
import { IPaymentListStaff } from '@partsbadger/types';
import { FilePdfOutlined } from '@ant-design/icons';
import ActionMenu from '../../TableActions/TableActions';
import { BadgerTable } from '../../badger-ui';
import { Price } from '@partsbadger/library';

interface Props {
    order_id: number;
}

const Payments = (props: Props) => {
    const columns: ColumnProps<IPaymentListStaff>[] = [
        {
            title: 'Date',
            dataIndex: 'created_time',
            key: 'created_time',
            render: (text, record) => {
                return new Date(record.created_time).toLocaleDateString();
            },
        },
        {
            title: 'Payment Status',
            dataIndex: 'payment_status',
            key: 'payment_status',
            render: (text, record) => {
                return (
                    <Typography.Text type={text == 'COMPLETED' ? 'success' : 'warning'}>
                        {record.payment_status}
                    </Typography.Text>
                );
            },
        },
        {
            title: 'Payment Method',
            dataIndex: 'payment_method',
            key: 'payment_method',
        },

        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            render: (text, record) => {
                return <Price value={record.amount} />;
            },
        },

        {
            title: 'Actions',
            key: 'actions',
            render: (text, record) => {
                const actions = [
                    {
                        key: 'receipt',
                        text: 'Receipt',
                        icon: <FilePdfOutlined />,
                        disabled: !record.receipt_url,
                        onClick: () => window.open(record.receipt_url ?? '#', '_blank'),
                    },
                ];

                return <ActionMenu actions={actions} />;
            },
        },
    ];

    return (
        <BadgerTable<IPaymentListStaff>
            rowKey={record => record.id.toString()}
            columns={columns}
            className="border"
            dataSource={SalesOrderStore.payments}
            pagination={{
                totalRecords: SalesOrderStore.invoices.length,
            }}
            locale={{
                emptyText: (
                    <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={<span style={{ color: '#000' }}>This order does not have records.</span>}
                    />
                ),
            }}
        />
    );
};

export default Payments;
