import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Button, Empty, message, Typography } from 'antd';
import { ColumnProps } from 'antd/es/table';

import { IShipmentProps } from '../../../../utils/types';
import { SalesOrderStore } from '../../../stores/SalesOrderStore';
import { DownloadFile } from '@partsbadger/utils';
import { SalesOrderProps } from '@partsbadger/types';
import { observer } from 'mobx-react';
import TrackingLink from '../../Buttons/TrackingLink';
import { BadgerTable } from '../../badger-ui';
import { useHistory } from 'react-router-dom';
import ActionMenu from '../../TableActions/TableActions';
import { DownloadOutlined } from '@ant-design/icons';

interface IProps {
    order: SalesOrderProps;
}

const Shipments: React.FC<IProps> = props => {
    const [loading, setLoading] = useState<boolean>(false);
    const history = useHistory();

    useEffect(() => {
        if (props.order?.id) {
            console.log('props.order?.id', props.order?.id);
        }
    }, [props.order?.id]);

    const getPackList = async (id: number, box_id: number) => {
        setLoading(true);
        SalesOrderStore.getPackListBySalesOrderId(id, box_id)
            .then(data => {
                DownloadFile(data, `${props.order?.carrier ? props.order?.carrier + '-' + box_id : 'pack-' + id}.pdf`);
                setLoading(false);
                message.success('Document downloaded!');
            })
            .catch(error => message.error(`Error loading PackList: ${error}`))
            .finally(() => setLoading(false));
    };

    const columns: ColumnProps<IShipmentProps>[] = [
        {
            title: 'ID',
            dataIndex: 'sales_order_outtake',
            key: 'sales_order_outtake',
            render(text, record) {
                return (
                    <Typography.Link
                        disabled={!record.sales_order_outtake}
                        onClick={() => {
                            history.push(`/shipments/${record.sales_order_outtake}`);
                        }}
                    >
                        {record.sales_order_outtake}
                    </Typography.Link>
                );
            },
        },
        {
            title: 'Ship Date',
            dataIndex: 'date',
            key: 'date',
            render: (text, record) => {
                if (record.created_time != null) {
                    return <span>{dayjs(record.created_time).add(7, 'day').format('YYYY-MM-DD')}</span>;
                }
                return <span>--</span>;
            },
        },
        {
            title: 'Destination',
            dataIndex: 'destination',
            key: 'destination',
        },

        {
            title: 'Carrier',
            dataIndex: 'carrier',
            key: 'carrier',
        },
        {
            title: 'Tracking',
            dataIndex: 'tracking_number',
            key: 'tracking_number',
            // align: 'center',
            render: (text, record) => {
                if (record.tracking_number != null) {
                    if (record.carrier != null) {
                        return <TrackingLink carrier={record.carrier} trackingCode={record.tracking_number} />;
                    }
                }
                return <span>--</span>;
            },
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => {
                return (
                    <ActionMenu
                        actions={[
                            {
                                key: 'view',
                                icon: <DownloadOutlined />,
                                text: 'View Shipment',
                                onClick: () => {
                                    history.push(`/shipments/${record.id}`);
                                },
                            },
                            {
                                key: 'download',
                                icon: <DownloadOutlined />,
                                text: 'Download Packing Slip',
                                hidden: record.destination != 'Customer',
                                onClick: () => getPackList(props.order?.id, record.id),
                            },
                        ]}
                    />
                );
            },
        },
    ];

    return (
        <BadgerTable<IShipmentProps>
            rowKey={record => record.id.toString()}
            columns={columns}
            className="border"
            dataSource={SalesOrderStore.shipments}
            pagination={{
                totalRecords: SalesOrderStore.shipments.length,
            }}
            locale={{
                emptyText: (
                    <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={<span style={{ color: '#000' }}>This order does not have shipments.</span>}
                    />
                ),
            }}
        />
    );
};

export default observer(Shipments);
