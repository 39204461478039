import React from 'react';
import { Typography } from 'antd';

const { Text } = Typography;

interface Props {
    required_partner_ship_date: string;
    revised_ship_date: string | null;
}

const PartnerShipDate = (props: Props) => {
    const { required_partner_ship_date, revised_ship_date } = props;

    return (
        <span>
            <Text italic={!!required_partner_ship_date} delete={!!revised_ship_date} strong={!revised_ship_date}>
                {required_partner_ship_date?.toString() || 'N/A'}
            </Text>
            {revised_ship_date ? (
                <Text
                    strong
                    style={{
                        marginLeft: '8px',
                    }}
                >
                    {revised_ship_date?.toString() || 'N/A'}
                </Text>
            ) : null}
        </span>
    );
};

export default PartnerShipDate;
