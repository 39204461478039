import React from 'react';
import { Empty, Typography } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { SalesOrderStore } from '../../../stores/SalesOrderStore';
import { IPurchaseOrderListStaff } from '@partsbadger/types';
import { observer } from 'mobx-react';
import { BadgerTable } from '../../badger-ui';
import { Price } from '@partsbadger/library';
import { useHistory } from 'react-router-dom';

interface IProps {
    order_id: number;
}

const PurchaseOrders: React.FC<IProps> = props => {
    const history = useHistory();
    const columns: ColumnProps<IPurchaseOrderListStaff>[] = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => {
                return (
                    <Typography.Link
                        onClick={() => {
                            history.push(`/purchase-orders/${record.id}`);
                        }}
                    >
                        {record.name}
                    </Typography.Link>
                );
            },
        },

        {
            title: 'Due Date',
            dataIndex: 'due_date',
            key: 'date',
        },
        {
            title: 'Category',
            dataIndex: 'category',
            key: 'category',
        },

        {
            title: 'Status',
            dataIndex: 'receive_status',
            key: 'receive_status',
            render: (text, record) => {
                return <span>{record.receive_status}</span>;
            },
        },
        {
            title: 'Total',
            dataIndex: 'grand_total',
            key: 'grand_total',
            render: (text, record) => {
                return <Price value={record.grand_total} />;
            },
        },
    ];

    return (
        <BadgerTable<IPurchaseOrderListStaff>
            rowKey={record => record.id.toString()}
            columns={columns}
            className="border"
            dataSource={SalesOrderStore.purchase_orders}
            pagination={{
                totalRecords: SalesOrderStore.purchase_orders.length,
            }}
            locale={{
                emptyText: (
                    <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={<span style={{ color: '#000' }}>This order does not have records.</span>}
                    />
                ),
            }}
        />
    );
};

export default observer(PurchaseOrders);
