import React, { Fragment, useEffect, useState } from 'react';
import { Col, Divider, Drawer, notification, Row, Table, Tag, Typography } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';

import { get, post } from '../../shared';

import { SalesOrderFilterForm } from '../../components/SalesOrder';
import { useQueryParameters, ZOHO_BASE_URL } from '@partsbadger/utils';
import { PaginationComponent } from '../../components/Inputs/Pagination';

import { DateRender, Price } from '@partsbadger/library';
import { SalesOrderListProps } from '@partsbadger/types';
import Stages from '../../components/Stages';

import { PaginationProps } from 'antd/es/pagination';
import SendToZohoButton from '../../components/Buttons/SendToZohoButton';
import PullFromZohoButton from '../../components/Buttons/PullFromZohoButton';
import { SalesOrder, SalesOrderRequest } from '../../Domain/interfaces/SalesOrders/SalesOrderInterface';
import { observer } from 'mobx-react';
import OrderListActions from '../../components/SalesOrder/OrderListActions';
import { Chat } from '@partsbadger/quote-tool';

const { Column } = Table;

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export const OrdersList = observer((props: any) => {
    const [loading, setLoading] = useState(false);
    const [orders, setOrders] = useState<SalesOrder[]>([]);

    const searchParams = useQuery();
    const [pagination, setPagination] = useState<PaginationProps>({});
    const params = useQueryParameters();

    const history = useHistory();
    const [sales_order_notifications, setSalesOrderNotifications] = useState([]);

    const [sales_order_notification_id, setSalesOrderNotificationId] = useState<number>(0);

    useEffect(() => {
        if (sales_order_notification_id > 0) {
            get(`staff/sales-orders/${sales_order_notification_id}/notifications/`).then((response: any) => {
                setSalesOrderNotifications(response.data);
            });
        }
    }, [sales_order_notification_id]);

    useEffect(() => {
        const show_last_message = Number(params.get('show-last-message'));
        if (show_last_message) setSalesOrderNotificationId(show_last_message);
    }, []);

    const getOrders = (params = {}) => {
        setLoading(true);
        get('staff/sales-orders/', params)
            .then((response: SalesOrderRequest) => {
                setOrders(response.data.results);
                setPagination({
                    pageSize: 30,
                    total: response.data.count,
                });
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        getOrders(searchParams);
    }, [props.location.search]);

    return (
        <Fragment>
            <SalesOrderFilterForm />

            <Divider>
                <div className={'title'}> Sales Orders</div>
            </Divider>

            <Row>
                <Col span={24}>
                    <Table<SalesOrderListProps>
                        rowKey={'id'}
                        dataSource={orders}
                        loading={loading}
                        pagination={false}
                        footer={() => {
                            return <PaginationComponent {...pagination} />;
                        }}
                        scroll={{ x: 'auto' }}
                    >
                        <Column title="Nro" dataIndex="id" key="id" />
                        <Column<SalesOrderListProps>
                            title="Name"
                            dataIndex="name"
                            key="name"
                            render={(text, record) => {
                                return (
                                    <div>
                                        <Typography.Link
                                            disabled={record?.status == 'Draft'}
                                            onClick={() => history.push(`/new-sales-order-detail/${record?.id}/`)}
                                        >
                                            {record?.name}
                                        </Typography.Link>
                                    </div>
                                );
                            }}
                        />
                        <Column<SalesOrderListProps>
                            title="Order Submitted Time"
                            dataIndex="order_submitted_time"
                            key="created_time"
                            render={(text, record) => {
                                return (
                                    <div>
                                        <div>
                                            <DateRender value={record?.order_submitted_time} />
                                        </div>
                                        <div className={'italic'}>By {record.created_by.fullname}</div>
                                    </div>
                                );
                            }}
                        />

                        <Column title="Source" dataIndex="source" key="source" />

                        <Column<SalesOrderListProps>
                            title="Account"
                            dataIndex="account"
                            key="account"
                            render={(text, record) => {
                                if (record?.account) {
                                    return (
                                        <Typography.Link
                                            onClick={() => history.push(`/accounts/${record.account.id}/`)}
                                        >
                                            {record.account.name}
                                        </Typography.Link>
                                    );
                                }
                            }}
                        />

                        <Column<SalesOrderListProps>
                            title="Contact"
                            dataIndex="contact"
                            key="contact"
                            render={(text, record) => {
                                if (record.contact) {
                                    return record.contact.fullname;
                                }
                            }}
                        />

                        <Column<SalesOrderListProps>
                            title="Design Engineer"
                            dataIndex="design_engineer"
                            key="design_engineer"
                            render={(text, record) => {
                                if (record.design_engineer) {
                                    return record.design_engineer.fullname;
                                }
                            }}
                        />

                        <Column<SalesOrderListProps>
                            title="In Zoho"
                            dataIndex="zoho_id"
                            key="zoho_id"
                            render={(text, record) => {
                                if (record.zoho_id) {
                                    return (
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                justifyContent: 'left',
                                                width: '100%',
                                            }}
                                        >
                                            <div>
                                                <a
                                                    target={'_blank'}
                                                    rel="noreferrer"
                                                    href={`${ZOHO_BASE_URL}/SalesOrders/${record.zoho_id}`}
                                                >
                                                    View in Zoho
                                                </a>
                                            </div>

                                            <div className={'cursor-pointer ml-2'}>
                                                <PullFromZohoButton
                                                    module={'sales-orders'}
                                                    id={record.id}
                                                    onFinish={() => {
                                                        notification.success({
                                                            duration: 2,
                                                            message: 'Order synced from Zoho successfully',
                                                        });
                                                        getOrders();
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    );
                                }

                                if (record.zoho_id === null && record.status == 'Ready for Production') {
                                    return (
                                        <div>
                                            <SendToZohoButton
                                                module="sales-orders"
                                                id={record.id}
                                                onFinish={() => {
                                                    getOrders();
                                                }}
                                            />
                                        </div>
                                    );
                                }
                            }}
                        />

                        <Column<SalesOrderListProps>
                            title="Status"
                            dataIndex="status"
                            key="status"
                            render={(text, record) => {
                                return (
                                    <div className={'flex flex-col w-full'}>
                                        <div>
                                            <Stages stage={record.status} />
                                        </div>
                                        <div className="mt-1">
                                            {record.partsbadger_production ? <Tag color="blue">PB Prod</Tag> : ''}
                                        </div>
                                    </div>
                                );
                            }}
                        />

                        <Column<SalesOrderListProps>
                            title="Sales Rep"
                            dataIndex="owner"
                            key="owner"
                            render={(text, record) => {
                                return <span>{record.owner.fullname}</span>;
                            }}
                        />

                        <Column<SalesOrderListProps>
                            title="Grand Total"
                            dataIndex="grand_total"
                            key="grand_total"
                            render={(text, record) => {
                                return <Price value={record.grand_total} />;
                            }}
                        />

                        <Column<SalesOrderListProps>
                            title="Notifications"
                            render={(text, record) => {
                                return (
                                    <div>
                                        <div
                                            className={'link'}
                                            onClick={() => {
                                                setSalesOrderNotificationId(record.id);
                                            }}
                                        >
                                            Messages
                                        </div>
                                    </div>
                                );
                            }}
                        />

                        <Column<SalesOrderListProps>
                            title={'Actions'}
                            key={'actions'}
                            render={(text, record) => {
                                return <OrderListActions record={record} />;
                            }}
                        />
                    </Table>
                </Col>
            </Row>

            {sales_order_notification_id > 0 && (
                <>
                    <Drawer
                        title="Notifications"
                        placement="right"
                        closable={true}
                        width={600}
                        onClose={() => {
                            setSalesOrderNotificationId(0);
                        }}
                        open={true}
                    >
                        <Row>
                            <Chat
                                height={450}
                                messages={sales_order_notifications}
                                onSendMessage={(payload: any) => {
                                    const formData = new FormData();

                                    if (payload.body) {
                                        formData.append('body', payload.body);
                                    }

                                    payload.attachments.forEach((item: any) => {
                                        formData.append('attachments_files', item);
                                    });

                                    const url = `staff/sales-orders/${sales_order_notification_id}/notifications/`;

                                    post(url, formData, 1, true)
                                        .then((response: any) => {
                                            setSalesOrderNotifications(response.data);
                                        })
                                        .catch((error: any) => notification.error(error));
                                }}
                            />
                        </Row>
                    </Drawer>
                </>
            )}
        </Fragment>
    );
});
