import React from 'react';
import { Empty, Typography } from 'antd';
import { SalesOrderStore } from '../../../stores/SalesOrderStore';
import { ColumnProps } from 'antd/es/table';
import { ICreditMemoListStaff } from '@partsbadger/types';
import { useHistory } from 'react-router-dom';
import { FilePdfOutlined } from '@ant-design/icons';
import ActionMenu from '../../TableActions/TableActions';
import { BadgerTable } from '../../badger-ui';
import { Price } from '@partsbadger/library';
import { ZOHO_BASE_URL } from '../../../constants';

interface Props {
    order_id: number;
}

const CreditMemos = (props: Props) => {
    const history = useHistory();

    const handlePrint = (invoice_url?: string) => {
        if (invoice_url) {
            window.open(invoice_url, '_blank', 'width=800,height=800,left=200,top=200');
        }
    };

    const columns: ColumnProps<ICreditMemoListStaff>[] = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => {
                return <div>{record.name}</div>;
            },
        },
        {
            title: 'Date',
            dataIndex: 'created_time',
            key: 'created_time',
            render: (text, record) => {
                return new Date(record.created_time).toLocaleDateString();
            },
        },

        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            render: (text, record) => {
                return <Price value={record.amount} />;
            },
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (text, record) => {
                const actions = [
                    {
                        key: 'view-in-zoho',
                        text: 'View in Zoho',
                        icon: <FilePdfOutlined />,
                        disabled: !record.zoho_id,
                        onClick: () => window.open(`${ZOHO_BASE_URL}/CustomModule14/${record.zoho_id}`),
                    },

                    {
                        key: 'download-pdf',
                        icon: <FilePdfOutlined />,
                        text: 'Download PDF',
                        onClick: () => record.zoho_id && SalesOrderStore.downloadCreditNotePdf(record.id, record?.name),
                    },
                ];

                return <ActionMenu actions={actions} />;
            },
        },
    ];

    return (
        <BadgerTable<ICreditMemoListStaff>
            rowKey={record => record.id.toString()}
            columns={columns}
            className="border"
            dataSource={SalesOrderStore.credit_memos}
            pagination={{
                totalRecords: SalesOrderStore.invoices.length,
            }}
            locale={{
                emptyText: (
                    <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={<span style={{ color: '#000' }}>This order does not have records.</span>}
                    />
                ),
            }}
        />
    );
};

export default CreditMemos;
