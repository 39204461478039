import React, { useEffect, useState, useCallback } from 'react';
import { Alert, Button, Card, Checkbox, Col, Collapse, DatePicker, Divider, Form, Input, InputNumber, message, Modal, notification, Row, Typography, Upload, } from 'antd';
import FeatherIcon from 'feather-icons-react';
import { observer } from 'mobx-react';
import { AdditionalRequirements, FormErrorParser, Mobile, Price } from '@partsbadger/library';
import { CartItem } from './CartItem';
import QuoteStore from '../../stores/QuoteStore';
import { Accounts, AcquisitionSource, ContactAcquisitionSource, Purchasing, Users } from '../DropDowns';
import { API_ROOT, DownloadFile, get } from '@partsbadger/utils';
import { QuestionMark } from '../QuestionMark';
import { LeadStore } from '../../stores/LeadStore';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';
import { RichTextEditor } from '@partsbadger/library';
import { ZOHO_BASE_URL } from '@partsbadger/utils';
import { CheckCircleOutlined } from '@ant-design/icons';
const { Text } = Typography;
const TextArea = Input.TextArea;
export const CheckoutForm = observer((props) => {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [needByAnswerDate, setNeedByAnswerDate] = useState('');
    const [formErrors, setFormErrors] = useState(null);
    const { onQuoteSubmitted } = props;
    const [form] = Form.useForm();
    const { getFieldValue } = form;
    const { line_items } = QuoteStore.quote || {};
    const [quote_completed, setQuoteCompleted] = useState(false);
    const [quote_name, setQuoteName] = useState('');
    const [quote_id, setQuoteID] = useState(0);
    const { getFirstName, getLastName, getEmail, getPhone, getCompany, getZip, getTitle, for_staff, additional_requirements, getRelatedContact, getAdditionalFiles, contact, } = QuoteStore;
    const quote = QuoteStore.quote;
    // If there are error fields in the line items, scroll to them on form submit and expand product if it is collapsed
    useEffect(() => {
        if (QuoteStore.errorFields?.length > 0) {
            if (QuoteStore.collapsedLineItems.includes(Number(QuoteStore.errorFields[0]?.lineItem))) {
                QuoteStore.setCollapsed(QuoteStore.errorFields[0]?.lineItem);
            }
            document.getElementById(QuoteStore.errorFields[0]?.lineItem)?.scrollIntoView(false);
            setLoading(false);
        }
    }, [loading]);
    const handleSubmitRFQ = async (save_for_later) => {
        form.validateFields()
            .then(async (values) => {
            setLoading(true);
            if (QuoteStore.errorFields?.length === 0) {
                if (!quote?.line_items?.length) {
                    message.error('Please select your parts files');
                    return null;
                }
                let file_2d_required = '';
                line_items?.forEach(item => {
                    if (item?.product?.material.includes('Customer')) {
                        values.customer_supplied_material = true;
                    }
                    if (item?.product?.material.includes('Review 2D') && !item?.product?.has_file_2d) {
                        file_2d_required = item.name;
                    }
                    item?.product?.finishes.forEach(finish => {
                        if (finish == 'Review 2D' && !item?.product?.has_file_2d) {
                            file_2d_required = item.name;
                        }
                    });
                    item?.product?.coatings.forEach(coating => {
                        if (coating == 'Review 2D' && !item?.product?.has_file_2d) {
                            file_2d_required = item.name;
                        }
                    });
                });
                if (LeadStore.IsLead) {
                    const convertLeadResponse = await convertLead();
                    // Verify the status the convertLeadToContact.
                    if (convertLeadResponse.status != 200 && convertLeadResponse.status != 202) {
                        // No Execute QuoteStore.sendToReview and end the process
                        setLoading(false);
                        return;
                    }
                }
                const payload = {
                    first_name: values.first_name,
                    last_name: values.last_name,
                    email: values.email,
                    phone: values.phone,
                    company: values.company,
                    other_notes: values.other_notes,
                    customer_statement: values.customer_statement,
                    requote_changes: values.requote_changes,
                    shipping_zip_code: values.zip_code,
                    customer_rfq_reference: values.customer_rfq_reference,
                    acquisition_source: values.acquisition_source || 'Portal',
                    source: QuoteStore.for_staff ? 'One True Form - Staff' : 'One True Form - Customer',
                    create_3d_files: values.create_3d_files || false,
                    create_2d_files: values.create_2d_files || false,
                    additional_files: values.additional_files?.fileList?.map((f) => f.response?.id ?? Number(f.uid)),
                    lead_time: values.lead_time,
                    is_a_referral: values.is_a_referral,
                    initiate_go_team: values.initiate_go_team,
                    domestic_quoting: values.domestic_quoting,
                    customer_supplied_material: values.customer_supplied_material,
                    purchasing_type: values.purchasing_type,
                    related_contact: values.related_contact,
                    title: values.title,
                    go_team_info: {
                        why: values.why || null,
                        need_by_answer: needByAnswerDate || null,
                    },
                };
                if (values.account?.key) {
                    payload['account'] = values.account.key;
                    payload['company'] = values.account.label;
                }
                if (values?.owner?.key) {
                    payload['owner'] = values.owner.key;
                }
                if (values.additional_requirements) {
                    payload['custom_requirements'] = values.additional_requirements;
                }
                if (save_for_later) {
                    payload['notes'] = values.other_notes;
                    QuoteStore.updateQuote(payload).finally(() => setLoading(false));
                }
                else {
                    QuoteStore.sendToReview(payload)
                        .then(data => {
                        setLoading(false);
                        LeadStore.clearLead();
                        if (data.quote_stage === 'Completed') {
                            setQuoteCompleted(true);
                            setQuoteID(data.quote_id);
                            setQuoteName(data.quote_name);
                        }
                        else {
                            QuoteStore.setQuote(null);
                            if (onQuoteSubmitted) {
                                onQuoteSubmitted({
                                    user: data.user,
                                    first_name: values.first_name,
                                    last_name: values.last_name,
                                    email: values.email,
                                    company: values.company,
                                    ...data,
                                });
                            }
                            const date = new Date();
                            // Push google tracking event to window.dataLayer
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            window?.dataLayer?.push({
                                event: 'submitted-rfq',
                                url: window.location.toString(),
                                time: date.getTime(),
                            });
                        }
                    })
                        .catch(err => {
                        message.error('Error');
                        setLoading(false);
                    });
                }
            }
        })
            .catch(errorInfo => {
            notification.error({
                key: 'form-errors',
                duration: 8,
                message: 'Please input all required fields',
                description: React.createElement(FormErrorParser, { isAntd4: true, errors: errorInfo.errorFields }),
                placement: 'top',
                maxCount: 1,
            });
        });
    };
    const handleSubmitSaveForLater = async () => {
        line_items?.filter(item => !item.has_dynamic_quantities);
        let file_2d_required = '';
        let customer_supplied_material = false;
        line_items?.forEach(item => {
            if (item?.product?.material?.includes('Customer')) {
                customer_supplied_material = true;
            }
            if (item?.product?.material?.includes('Review 2D') && !item?.product?.has_file_2d) {
                file_2d_required = item.name;
            }
            item?.product?.finishes?.forEach(finish => {
                if (finish == 'Review 2D' && !item?.product?.has_file_2d) {
                    file_2d_required = item.name;
                }
            });
            item?.product?.coatings?.forEach(coating => {
                if (coating == 'Review 2D' && !item?.product?.has_file_2d) {
                    file_2d_required = item.name;
                }
            });
        });
        if (file_2d_required != '') {
            message.error('2D file is required in ' + file_2d_required, 5);
            return null;
        }
        const payload = {
            first_name: form.getFieldValue('first_name'),
            last_name: form.getFieldValue('last_name'),
            email: form.getFieldValue('values.email'),
            phone: form.getFieldValue('values.phone'),
            company: form.getFieldValue('company'),
            other_notes: form.getFieldValue('other_notes'),
            customer_statement: form.getFieldValue('customer_statement'),
            requote_changes: form.getFieldValue('requote_changes'),
            shipping_zip_code: form.getFieldValue('zip_code'),
            customer_rfq_reference: form.getFieldValue('customer_rfq_reference'),
            acquisition_source: form.getFieldValue('acquisition_source'),
            source: QuoteStore.for_staff ? 'One True Form - Staff' : 'One True Form - Customer',
            create_3d_files: form.getFieldValue('create_3d_files') || false,
            create_2d_files: form.getFieldValue('create_2d_files') || false,
            additional_files: form
                .getFieldValue('additional_files')
                ?.fileList?.map((f) => f.response?.id ?? Number(f.uid)),
            lead_time: form.getFieldValue('lead_time'),
            is_a_referral: form.getFieldValue('is_a_referral'),
            initiate_go_team: form.getFieldValue('initiate_go_team') || false,
            domestic_quoting: form.getFieldValue('domestic_quoting'),
            customer_supplied_material: customer_supplied_material,
            purchasing_type: form.getFieldValue('purchasing_type'),
            related_contact: form.getFieldValue('related_contact'),
            title: form.getFieldValue('title'),
            go_team_info: {
                why: form.getFieldValue('why') || null,
                need_by_answer: needByAnswerDate || null,
            },
        };
        if (form.getFieldValue('account')?.key) {
            payload['account'] = form.getFieldValue('account').key;
            payload['company'] = form.getFieldValue('account').label;
        }
        if (form.getFieldValue('owner')?.key) {
            payload['owner'] = form.getFieldValue('owner').key;
        }
        if (form.getFieldValue('additional_requirements')) {
            payload['custom_requirements'] = form.getFieldValue('additional_requirements');
        }
        payload['notes'] = form.getFieldValue('other_notes');
        setLoading(true);
        QuoteStore.updateQuote(payload).finally(() => setLoading(false));
        LeadStore.clearLead();
        notification.success({ message: 'Saved it for later!' });
    };
    const downloadQuote = (quote_id, quote_name) => {
        get(`/staff/quotes/${quote_id}/export/`, { responseType: 'blob' }).then(async (response) => {
            await DownloadFile(response, `quote-${quote_name}.pdf`);
            notification.success({ message: 'Quote PDF Downloaded' });
        });
    };
    const debouncedFormValidation = React.useCallback(_.debounce(() => {
        form.validateFields()
            .then(values => {
            setFormErrors(null);
        })
            .catch(data => {
            setFormErrors(data.errorFields);
        });
    }, 500), []);
    const onValuesChange = () => {
        debouncedFormValidation();
    };
    const user = QuoteStore.current_user;
    const popup = (url) => {
        window.open(url, '_blank', 'width=800,height=800,left=200,top=200');
    };
    const updateAccount = async (payload = {}) => {
        // This is only for the staff side
        let account_id = null;
        const account = getFieldValue('account');
        if (quote && quote.account_related) {
            account_id = quote.account_related.id;
        }
        else if (account) {
            account_id = account.value;
        }
        if (account_id) {
            await QuoteStore.update_account_field(account_id, payload);
        }
        else {
            message.error('First select an account');
        }
    };
    const convertLead = async () => {
        if (LeadStore.IsLead) {
            const payload = {
                lead_zoho_id: LeadStore.lead?.lead_zoho_id ?? null,
                account_id: form.getFieldValue('account').key ?? null,
                account_zoho_id: null,
                title: form.getFieldValue('title') ?? null,
                acquisition_source: form.getFieldValue('acquisition_source_contact') ?? null,
                phone: form.getFieldValue('phone') ?? '',
                referral: form.getFieldValue('is_a_referral') ?? false,
                referral_name: form.getFieldValue('referral_name') ?? null,
            };
            setLoading(true);
            try {
                const response = await LeadStore.convertLeadToContact(payload);
                notification.success({
                    message: 'Info!',
                    description: response.message,
                    placement: 'top',
                });
                return response;
            }
            catch (error) {
                notification.error({
                    message: 'Conversion to Lead Failed. Internal Error: ' + error.response.message,
                    placement: 'topRight',
                });
                return error.response;
            }
            finally {
                setLoading(false);
            }
        }
    };
    useEffect(() => {
        if (LeadStore.account?.id) {
            form.setFieldsValue({
                account: {
                    key: LeadStore.account?.id,
                    label: LeadStore.account?.name,
                },
            });
            QuoteStore.update_quote_field('account', LeadStore.account?.id);
        }
    }, [LeadStore.account?.id]);
    useEffect(() => {
        form.setFieldsValue({
            potential_dollars: quote?.account_related?.potential_dollars,
            high_or_potential_high_value_40k: quote?.account_related?.high_or_potential_high_value_40k,
            research_notes: quote?.account_related?.research_notes,
            pb_vendor_number: quote?.account_related?.pb_vendor_number,
            special_requirements: quote?.account_related?.special_requirements,
            quoting_notes: quote?.account_related?.quoting_notes,
        });
    }, [quote?.account_related]);
    const hasDataInAccount = () => {
        // Check if one of the 6 special fields of the account has data.
        return (form.getFieldValue('potential_dollars') ||
            form.getFieldValue('high_or_potential_high_value_40k') ||
            form.getFieldValue('research_notes') ||
            form.getFieldValue('pb_vendor_number') ||
            form.getFieldValue('special_requirements') ||
            form.getFieldValue('quoting_notes'));
    };
    const debouncedSaveCustomerRFReference = useCallback(_.debounce(html => {
        QuoteStore.update_quote_field('customer_rfq_reference', html);
    }, 500), []);
    return (React.createElement(React.Fragment, null,
        React.createElement(Form, { form: form, colon: false, onValuesChange: onValuesChange, onFinish: () => handleSubmitRFQ(false), onFinishFailed: errorInfo => {
                setFormErrors(errorInfo.errorFields);
            }, layout: "vertical", scrollToFirstError: true, initialValues: {
                lead_time: quote?.lead_time,
            }, className: 'checkout' },
            React.createElement("input", { type: "hidden", id: "zc_gad", name: "zc_gad", value: "" }),
            React.createElement(Row, { gutter: [24, 0] },
                React.createElement(Col, { md: 12, span: 24 },
                    React.createElement("h2", { className: "my-4 font-bold", style: { fontSize: '1.5rem' } }, "Delivery & Requirements"),
                    React.createElement(Card, null,
                        React.createElement(Row, { gutter: [12, 0] },
                            React.createElement(Col, { md: 24 },
                                React.createElement(Form.Item, { name: "lead_time", rules: [{ required: false, message: 'Please select a value!' }], initialValue: quote?.lead_time, label: React.createElement("span", null,
                                        "Target Lead Time",
                                        React.createElement(QuestionMark, null, "If you have a required lead time that you would like to meet, please enter it in business days.")) },
                                    React.createElement(InputNumber, { min: 1, size: "large", className: "w-full", style: { width: '100%' }, onBlur: e => {
                                            const value = e.target.value ? parseInt(e.target.value) : null;
                                            QuoteStore.update_quote_field('lead_time', value);
                                        } }))),
                            React.createElement(Col, { md: 24, span: 24 },
                                React.createElement(Form.Item, { name: "customer_supplied_material", rules: [
                                        {
                                            required: false,
                                        },
                                    ], initialValue: quote?.customer_supplied_material, valuePropName: 'checked', className: "mb-0" },
                                    React.createElement(Checkbox, { onChange: e => {
                                            QuoteStore.update_quote_field('customer_supplied_material', e.target.checked);
                                        } }, "Customer Supplied Material"))),
                            React.createElement(Col, { md: 24, span: 24 },
                                React.createElement(Form.Item, { name: "domestic_quoting", rules: [
                                        {
                                            required: false,
                                        },
                                    ], initialValue: quote?.domestic_quoting, valuePropName: 'checked' },
                                    React.createElement(Checkbox, { onChange: e => {
                                            QuoteStore.update_quote_field('domestic_quoting', e.target.checked);
                                        } }, "Domestic Production Required"))),
                            QuoteStore.needs2DFiles() ? (React.createElement(Col, { xl: 24, span: 24 },
                                React.createElement(Form.Item, { className: "mb-0", name: "create_2d_files", valuePropName: "checked", initialValue: quote?.create_2d_files },
                                    React.createElement(Checkbox, { onChange: e => {
                                            QuoteStore.update_quote_field('create_2d_files', e.target.checked);
                                        } },
                                        React.createElement(Text, { strong: true }, "Create 2D Files"))))) : null,
                            QuoteStore.needs3DFiles() ? (React.createElement(Col, { xl: 24, span: 24 },
                                React.createElement(Form.Item, { className: "mb-0", name: "create_3d_files", valuePropName: "checked", initialValue: quote?.create_3d_files },
                                    React.createElement(Checkbox, { defaultChecked: false, onChange: e => {
                                            QuoteStore.update_quote_field('create_3d_files', e.target.checked);
                                        } },
                                        React.createElement(Text, { strong: true }, "Create 3D Files"))))) : null,
                            QuoteStore.needs3DFiles() || QuoteStore.needs2DFiles() ? React.createElement(Divider, null) : null,
                            React.createElement(Col, { md: 24, span: 24 },
                                React.createElement(Form.Item, { name: "additional_requirements", rules: [{ required: false, message: 'Please select a value!' }], initialValue: quote?.custom_requirements ?? [], label: React.createElement(React.Fragment, null,
                                        "Additional Requirements",
                                        React.createElement(QuestionMark, null, "Please select any additional requirements like material certificate, First Article Inspection, Hardness Testing, etc. that you would like to be included with this quote.")), className: "mb-0" },
                                    React.createElement(AdditionalRequirements, { requirements: additional_requirements, onChange: values => {
                                            QuoteStore.update_quote_field('custom_requirements', values.map(v => {
                                                return {
                                                    key: v.key.toString(),
                                                    label: v.label,
                                                    value: v?.value ?? '',
                                                    // files: v.files,
                                                };
                                            }));
                                        } }))),
                            React.createElement(Col, { md: 24, span: 24 },
                                React.createElement(Form.Item, { name: "additional_files", label: React.createElement("span", null,
                                        "Additional Files",
                                        React.createElement(QuestionMark, null, "Please upload any additional files like NDA, Tax Exempt Form, Photos, etc.")), rules: [{ required: false, message: 'Please select a value!' }] },
                                    React.createElement(Upload.Dragger, { multiple: true, name: "file", showUploadList: true, action: `${API_ROOT}/customer/files/`, defaultFileList: getAdditionalFiles, "data-testid": "upload-file" },
                                        React.createElement("div", { className: "d-flex align-items-center align-middle justify-content-center mb-2 text-lg" },
                                            React.createElement(FeatherIcon, { icon: "upload-cloud" }),
                                            React.createElement("span", { className: "ml-2" }, "Click or drag files")),
                                        React.createElement("p", null, "Add any additional files like NDAs, Tax Exempt Forms, Photos, etc.")))),
                            QuoteStore.isRequote && (React.createElement(Col, { md: 24, span: 24 },
                                React.createElement(Form.Item, { name: "requote_changes", label: "ReQuote Changes", help: 'What changes did you make to ReQuote?', rules: [{ required: true, message: 'Please select a value!' }] },
                                    React.createElement(Checkbox.Group, { options: [
                                            { label: 'Quantity', value: 'Quantity' },
                                            { label: 'Lead Time', value: 'Lead Time' },
                                            { label: 'Material', value: 'Material' },
                                            { label: 'Coating or Finish', value: 'Coating or Finish' },
                                            { label: 'New File Revision', value: 'New File Revision' },
                                            { label: 'Add/Remove Lines', value: 'Add/Remove Lines' },
                                            { label: 'Pricing', value: 'Pricing' },
                                            {
                                                label: 'Additional Requirements',
                                                value: 'Additional Requirements',
                                            },
                                            { label: 'Refresh', value: 'Refresh' },
                                        ] })))),
                            for_staff && (React.createElement(Col, { span: 24 },
                                React.createElement(Form.Item, { name: "initiate_go_team", initialValue: quote?.initiate_go_team, valuePropName: 'checked' },
                                    React.createElement(Checkbox, { onChange: e => {
                                            QuoteStore.update_quote_field('initiate_go_team', e.target.checked);
                                        } },
                                        React.createElement(Text, { strong: true }, "Submit to the initiate Go team?"),
                                        React.createElement("br", null),
                                        React.createElement(Text, { type: "secondary" }, "Select to submit the RFQ/ReQuote to the initiate Go team"))),
                                getFieldValue('initiate_go_team') && (React.createElement(Col, { md: 24 },
                                    React.createElement(Row, null,
                                        React.createElement(Col, { md: 12 },
                                            React.createElement(Form.Item, { name: "why", rules: [
                                                    {
                                                        required: true,
                                                        message: 'Please select what do you need the Go Team for',
                                                    },
                                                ], initialValue: QuoteStore.quote?.go_team_info?.why ?? [], label: "Why?", help: 'What do you need the Go Team for?' },
                                                React.createElement(Checkbox.Group, { options: [
                                                        { label: 'Pricing', value: 'Pricing' },
                                                        { label: 'Lead Time', value: 'Lead Time' },
                                                        { label: 'Fast Lane', value: 'Fast Lane' },
                                                    ] }))),
                                        React.createElement(Col, { md: 12 },
                                            React.createElement(Form.Item, { name: "need_by_answer", label: "Need by Answer:", rules: [
                                                    {
                                                        required: false,
                                                        message: 'Please insert a date!',
                                                    },
                                                ], initialValue: QuoteStore.quote?.go_team_info?.need_by_answer
                                                    ? moment(QuoteStore.quote.go_team_info.need_by_answer)
                                                    : null },
                                                React.createElement(DatePicker, { onChange: (date, dateString) => setNeedByAnswerDate(dateString) })))))))),
                            React.createElement(Col, { md: 24, span: 24 },
                                React.createElement(Form.Item, { name: "other_notes", label: React.createElement("span", null, props.for_staff === false
                                        ? 'Please note why you would like to make these changes'
                                        : 'Customer Statement and Additional Notes'), className: "mb-0", help: 'Copy and paste the customer statement' },
                                    React.createElement(RichTextEditor, { className: "richtexteditor" }))),
                            QuoteStore.isRequote && QuoteStore.for_staff && QuoteStore.contact && (React.createElement(Col, { md: 24 },
                                React.createElement(Form.Item, { name: "owner", label: "Quote Owner", initialValue: {
                                        key: QuoteStore.contact.owner.id,
                                        label: QuoteStore.contact.owner.fullname,
                                    }, help: 'If empty the account owner will be used' },
                                    React.createElement(Users, null))))),
                        !QuoteStore.isRequote && QuoteStore.for_staff ? (React.createElement(Row, { gutter: [8, 0] },
                            React.createElement(Col, { xl: 12, span: 24 },
                                React.createElement(Form.Item, { name: "account", label: "Account", initialValue: QuoteStore?.quote?.account_related
                                        ? {
                                            key: QuoteStore?.quote?.account_related?.id,
                                            label: QuoteStore?.quote?.account_related?.name,
                                        }
                                        : null, rules: [
                                        {
                                            required: true,
                                            message: 'Please select an account',
                                        },
                                    ], hasFeedback: true, help: React.createElement("div", null,
                                        "If you don't see the account?",
                                        React.createElement("a", { target: "_blank", href: `${ZOHO_BASE_URL}/Accounts/create`, rel: "noreferrer" }, "create here")) },
                                    React.createElement(Accounts, { onChange: async (e) => {
                                            await QuoteStore.update_quote_field('account', e.value);
                                        } }))),
                            React.createElement(React.Fragment, null,
                                React.createElement(Col, { xl: 12, span: 24 },
                                    React.createElement(Form.Item, { name: "acquisition_source", label: "Deal Acquisition Source", rules: [
                                            {
                                                required: true,
                                                message: 'Please input an Acquisition Source',
                                            },
                                        ], initialValue: QuoteStore.quote?.acquisition_source ?? '' },
                                        React.createElement(AcquisitionSource, { acquisition_sources: QuoteStore.acquisition_source }))),
                                LeadStore.IsLead && (React.createElement(Col, { xl: 12, span: 24 },
                                    React.createElement(Form.Item, { name: "acquisition_source_contact", label: "Contact Acquisition Source", rules: [
                                            {
                                                required: true,
                                                message: 'Please input an Contact Acquisition Source',
                                            },
                                        ], initialValue: LeadStore.lead?.acquisition_source ?? '' },
                                        React.createElement(ContactAcquisitionSource, { acquisition_sources: QuoteStore.acquisition_source_contact })))),
                                ' ',
                                React.createElement(React.Fragment, null,
                                    React.createElement(Col, { xl: 12, span: 24 },
                                        React.createElement(Form.Item, { name: "related_contact", label: "Related Contact", rules: [{ type: 'email' }], initialValue: getRelatedContact, hasFeedback: true },
                                            React.createElement(Input, null))),
                                    React.createElement(Col, { xl: 12, span: 24 },
                                        React.createElement(Form.Item, { name: "purchasing_type", label: "Purchasing type", rules: [
                                                {
                                                    required: true,
                                                    message: 'Please select Purchasing Type',
                                                },
                                            ], initialValue: QuoteStore.quote?.account_related?.purchasing_type ?? null },
                                            React.createElement(Purchasing, null))),
                                    React.createElement(Col, { xl: 24, md: 24 },
                                        React.createElement(Form.Item, { name: "customer_rfq_reference", label: " Customer RFQ reference", initialValue: quote?.customer_rfq_reference, hasFeedback: true },
                                            React.createElement(RichTextEditor, { className: "richtexteditor", initialValue: quote?.customer_rfq_reference, onChange: html => debouncedSaveCustomerRFReference(html) }))),
                                    quote && quote.account_related && (React.createElement(Col, { xl: 24, md: 24 },
                                        React.createElement(Collapse, { className: 'mb-4' },
                                            React.createElement(Collapse.Panel, { header: React.createElement("div", null,
                                                    React.createElement("span", null, "Account Info"),
                                                    hasDataInAccount() && (React.createElement("span", { className: 'mx-2 font-bold text-red-500' }, "!"))), key: "1" },
                                                React.createElement(Row, null,
                                                    React.createElement(Col, { className: 'px-4', md: 12 },
                                                        React.createElement(Form.Item, { label: "Research/Onboarding Notes", name: 'research_notes', initialValue: quote?.account_related?.research_notes },
                                                            React.createElement(Input, { onBlur: async (e) => {
                                                                    await updateAccount({
                                                                        research_notes: e.target.value,
                                                                    });
                                                                } })),
                                                        React.createElement(Form.Item, { label: "PB Vendor Number", name: 'pb_vendor_number', initialValue: quote?.account_related?.pb_vendor_number },
                                                            React.createElement(Input, { onBlur: async (e) => {
                                                                    await updateAccount({
                                                                        pb_vendor_number: e.target.value,
                                                                    });
                                                                } })),
                                                        React.createElement(Form.Item, { label: "Special Requirements", name: 'special_requirements', initialValue: quote?.account_related?.special_requirements },
                                                            React.createElement(Input, { onBlur: async (e) => {
                                                                    await updateAccount({
                                                                        special_requirements: e.target.value,
                                                                    });
                                                                } }))),
                                                    React.createElement(Col, { className: 'px-4', md: 12 },
                                                        React.createElement(Form.Item, { label: "Quoting Notes", name: 'quoting_notes', initialValue: quote?.account_related?.quoting_notes },
                                                            React.createElement(Input, { onBlur: async (e) => {
                                                                    await updateAccount({
                                                                        quoting_notes: e.target.value,
                                                                    });
                                                                } })),
                                                        React.createElement(Form.Item, { label: "Potential Spend", name: 'potential_dollars', initialValue: quote?.account_related?.potential_dollars },
                                                            React.createElement(Input, { onBlur: async (e) => {
                                                                    await updateAccount({
                                                                        potential_dollars: e.target.value,
                                                                    });
                                                                } })),
                                                        React.createElement(Form.Item, { label: "High or Potential High Value", name: 'high_or_potential_high_value_40k', initialValue: quote?.account_related
                                                                ?.high_or_potential_high_value_40k },
                                                            React.createElement(Input, { onBlur: async (e) => {
                                                                    await updateAccount({
                                                                        high_or_potential_high_value_40k: e.target.value,
                                                                    });
                                                                } }))))))))),
                                React.createElement(Col, { md: 24 }, QuoteStore.contact && (React.createElement(Form.Item, { name: "owner", label: "Quote Owner", initialValue: {
                                        key: QuoteStore.contact.owner.id,
                                        label: QuoteStore.contact.owner.fullname,
                                    }, help: 'If empty the account owner will be used' },
                                    React.createElement(Users, null))))),
                            QuoteStore.default_documents?.length > 0 && (React.createElement(Col, { md: 24, className: 'py-4' },
                                React.createElement(Collapse, { accordion: true },
                                    React.createElement(Collapse.Panel, { header: "Review the uploaded documents for the contact and account.", key: "1" }, QuoteStore.default_documents.map((document) => {
                                        return (React.createElement(React.Fragment, null,
                                            React.createElement("a", { onClick: () => popup(document.file), rel: "noreferrer" }, document.filename),
                                            React.createElement("br", null)));
                                    }))))))) : null)),
                React.createElement(Col, { md: 12, span: 24 },
                    React.createElement("h2", { className: "my-4 font-bold", style: { fontSize: '1.5rem' } }, "Quote Summary"),
                    React.createElement(Card, null,
                        React.createElement("div", null,
                            QuoteStore.quote && QuoteStore.quote.line_items.length >= 1 && (React.createElement(Row, null,
                                QuoteStore.quote?.line_items?.map(item => {
                                    return (React.createElement(Col, { sm: 24, key: item.id, style: {
                                            marginBottom: 10,
                                        } },
                                        React.createElement(CartItem, { key: item.id, item: item })));
                                }),
                                React.createElement(Divider, { type: "horizontal" }))),
                            !QuoteStore.needsRFQ() && (React.createElement(Row, null,
                                React.createElement(Col, { md: 16 },
                                    React.createElement(Text, { className: "font-size-lg" }, "Total")),
                                React.createElement(Col, { md: 8, className: "text-right" },
                                    React.createElement(Text, { strong: true, className: "font-size-lg" },
                                        React.createElement(Price, { value: quote?.grand_total ?? 0 }))))),
                            React.createElement(React.Fragment, null,
                                !for_staff && QuoteStore.quote?.contact && props.isReceiveSms !== undefined && (React.createElement(Mobile, { contact: QuoteStore.quote?.contact, onChangeSmsNotification: value => QuoteStore.updateContactInfo(value) })),
                                React.createElement(React.Fragment, null,
                                    React.createElement(Row, { gutter: [8, 0] },
                                        React.createElement(Col, { span: 24 },
                                            React.createElement(Text, { strong: true, className: "d-block font-size-md" }, `${getFirstName} ${getLastName}`),
                                            React.createElement(Text, { className: "d-block font-size-md" }, getEmail)),
                                        !getFirstName && (React.createElement(Col, { xl: 12, span: 24 },
                                            React.createElement(Form.Item, { name: "first_name", label: "First Name", rules: [
                                                    {
                                                        required: true,
                                                        message: 'Please input the first name',
                                                    },
                                                ], initialValue: getFirstName, hasFeedback: true },
                                                React.createElement(Input, null)))),
                                        !getLastName && (React.createElement(Col, { xl: 12, span: 24 },
                                            React.createElement(Form.Item, { name: "last_name", label: "Last Name", rules: [
                                                    {
                                                        required: true,
                                                        message: 'Please input the last name',
                                                    },
                                                ], initialValue: getLastName, hasFeedback: true },
                                                React.createElement(Input, null)))),
                                        !getFieldValue('account') ? (React.createElement(Col, { md: 12, span: 24 },
                                            React.createElement(Form.Item, { name: "company", label: "Company", hasFeedback: true, rules: [{ required: true, message: 'Please input a Company' }], initialValue: getCompany },
                                                React.createElement(Input, null)))) : null,
                                        for_staff ? (React.createElement(Col, { md: 12, span: 24 },
                                            React.createElement(Form.Item, { name: "title", label: "Title", initialValue: getTitle, hasFeedback: true },
                                                React.createElement(Input, null)))) : null,
                                        getPhone === '' ? (React.createElement(Col, { md: 12, span: 24 },
                                            React.createElement(Form.Item, { name: "phone", label: "Phone", rules: [
                                                    {
                                                        required: false,
                                                        message: 'Please input a phone number',
                                                    },
                                                ], initialValue: getPhone, hasFeedback: true },
                                                React.createElement(Input, null)))) : null,
                                        React.createElement(Col, { md: 12, span: 24 },
                                            React.createElement(Form.Item, { name: "zip_code", label: React.createElement(React.Fragment, null,
                                                    "Zip/Postal Code",
                                                    ' ',
                                                    React.createElement(QuestionMark, null, "Zip code required for estimating sales tax")), rules: [
                                                    {
                                                        required: true,
                                                        message: 'Please insert a Zip/Postal Code',
                                                    },
                                                ], initialValue: getZip, hasFeedback: true },
                                                React.createElement(Input, { min: 1 }))),
                                        QuoteStore.isNewContact ||
                                            (LeadStore.IsLead && (React.createElement(React.Fragment, null,
                                                React.createElement(Col, { md: 12, span: 24 },
                                                    React.createElement(Form.Item, { name: "is_a_referral", valuePropName: 'checked' },
                                                        React.createElement(Checkbox, { defaultChecked: false, onChange: e => {
                                                                form.resetFields(['referral_name']);
                                                                LeadStore.IsLead &&
                                                                    LeadStore.setLead({
                                                                        ...LeadStore.lead,
                                                                        referral: e.target.checked,
                                                                    });
                                                            } }, "Is this a new referral from an Existing Contact or Lead?")),
                                                    React.createElement(Form.Item, { label: "Referral Name", name: 'referral_name', rules: [
                                                            {
                                                                required: form.getFieldValue('is_a_referral'),
                                                                message: 'Please input a referal name',
                                                            },
                                                        ] },
                                                        React.createElement(Input, { type: "text", disabled: !LeadStore?.lead?.referral }))))))),
                                    !for_staff && (React.createElement(Form.Item, { name: "accepted_terms_conditions", rules: [
                                            {
                                                validator: (_, value) => value
                                                    ? Promise.resolve()
                                                    : Promise.reject(new Error('Please accept the terms and conditions')),
                                            },
                                        ], valuePropName: "checked", initialValue: false },
                                        React.createElement("div", { className: "d-flex flex-row justify-content-start align-items-center mt-4", "data-testid": "checkbox-terms" },
                                            React.createElement(Checkbox, null,
                                                "I have read and accept the",
                                                ' ',
                                                React.createElement("a", { href: 'https://parts-badger.com/terms-and-conditions', rel: "noopener noreferrer", target: "_blank", className: "font-weight-bold underline" }, "terms and conditions.")))))),
                                !!formErrors && (React.createElement("div", { className: 'mt-5 mb-4' },
                                    React.createElement(Alert, { type: 'error', description: React.createElement(FormErrorParser, { isAntd4: true, errors: formErrors }) }))),
                                QuoteStore.for_staff && quote?.safe_to_autocomplete && props.is_tester && (React.createElement("div", null,
                                    React.createElement("div", { className: 'mt-5 mb-4' },
                                        React.createElement(Alert, { type: 'success', icon: React.createElement(CheckCircleOutlined, null), description: 'This quote meets all specifications for "Auto Green", press submit to get an approval from one of our engineers', showIcon: true })))),
                                React.createElement(Button, { id: "submit-for-engineer-review", block: true, type: 'primary', loading: loading ?? QuoteStore.loading, size: "large", className: "mb-2", htmlType: "submit" }, "Submit for Engineer Review"),
                                React.createElement("div", { className: "d-flex justify-center w-100" },
                                    React.createElement(Button, { type: 'default', htmlType: "button", onClick: () => handleSubmitSaveForLater(), loading: loading ?? QuoteStore.loading, size: "small", block: true }, "Save for Later")))))))),
        for_staff && props.is_tester && quote_completed && (React.createElement(Modal, { open: true, onCancel: () => {
                QuoteStore.setQuote(null);
                notification.success({
                    message: 'Quote sent!',
                });
                history.push('/rfq');
            }, closable: false, okText: 'Download Quote PDF', onOk: () => {
                downloadQuote(quote_id, quote_name);
                QuoteStore.setQuote(null);
                notification.success({
                    message: 'Downloading file...',
                });
                history.push('/rfq');
            } },
            React.createElement("span", null, "This quote is \"Auto Green\", and was autocompleted, you can download the PDF file.")))));
});
